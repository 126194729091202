import React from "react";
import { NavLink } from "react-router-dom";

import { StyledLogoWrapper } from "./styledLogo";
import { generatePath } from "../../../utils/getLanguage";

const Logo = () => {
  return (
    <StyledLogoWrapper
      as={NavLink}
      to={generatePath(localStorage.getItem("gamePage") ? localStorage.getItem("gamePage") : "/")}
    />
  );
};

export default Logo;