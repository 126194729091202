import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  StyledError,
  StyledFlexAlign,
  StyledHintText,
  StyledInput,
  StyledInputGroup,
  StyledInputWrapper,
  StyledLabel,
  StyledPasswordEye
} from "./styledInputGroup";

const InputGroup = React.forwardRef(({
  id,
  label,
  type,
  className,
  handleChange,
  handleInput = () => {
  },
  name,
  maxLength,
  autocomplete,
  error,
  position,
  betAction,
  hints,
  hintsWithMax,
  textAlign,
  padding,
  paddingInput,
  marginInput,
  width,
  height,
  fontSizeInput,
  autoInput,
  balance,
  ...otherProps
}, ref) => {
  const [typePassword, setTypePassword] = useState("password");
  const [classActive, setClassActive] = useState(false);

  const { t } = useTranslation("siteOptions");

  const changeType = () => {
    setTypePassword(typePassword === "password" ? "text" : "password");
  };

  let intervalClassActive;

  const setTemporaryClass = () => {
    if (balance) {
      setClassActive(true);
      intervalClassActive = setTimeout(() => {
        setClassActive(false);
      }, 500);
    }
  }

  useEffect(() => {
    setTemporaryClass();

    return () => {
      clearInterval(intervalClassActive);
    };
  }, [balance]);

  return (
    <StyledInputGroup className={`input-group ${className ?? ""}`} type={type} padding={padding} autoInput={autoInput}>
      {label && !betAction && <StyledLabel htmlFor={id}>{label}</StyledLabel>}
      {betAction &&
        <StyledFlexAlign position={position}>
          <StyledLabel htmlFor={id}>{label}</StyledLabel>
          {betAction}
        </StyledFlexAlign>}
      <StyledInputWrapper
        error={error}
        balance={balance}
        className={classActive && "active"}
        autoModeIsStart={false}
      >
        <StyledInput
          ref={ref}
          type={type === "password" ? typePassword : type}
          id={id}
          name={name}
          autoComplete={autocomplete}
          maxLength={maxLength}
          textAlign={textAlign}
          paddingInput={paddingInput}
          marginInput={marginInput}
          width={width}
          height={height}
          fontSizeInput={fontSizeInput}
          onChange={handleChange}
          onInput={(e) => handleInput(e?.target.value)}
          {...otherProps}
        />
        {type === "password" && <StyledPasswordEye
          className={typePassword === "password" ? "icon-eye-slash" : "icon-eye"}
          title={typePassword === "password" ? t('showPassword') : t('hidePassword')}
          type="button"
          onClick={changeType}
        />}
      </StyledInputWrapper>
      {error ?
        <StyledFlexAlign mt="10">
          <StyledError>{error ?? ''}</StyledError>
        </StyledFlexAlign> :
        null
      }
      {hints && !hintsWithMax &&
        <StyledFlexAlign mt="10">
          <StyledHintText
            style={{ alignSelf: 'flex-start' }}>{t('minimumAmount')}: {hints?.min ?? hints.fee.min} {hints.currency.asset}</StyledHintText>
          <div style={{ textAlign: "end", display: 'flex', flexDirection: 'column', gap: 5 }}>
            {hints?.commission !== undefined &&
              <StyledHintText>{t("commission")}: {hints.commission} {hints?.currency?.asset}</StyledHintText>}
            <StyledHintText>{t('yourBalance')}: {hints?.balances?.amount} {hints?.currency?.asset}</StyledHintText>
          </div>
        </StyledFlexAlign>
      }

      {
        hintsWithMax &&
        <StyledFlexAlign mt="10" style={{ alignItems: "start" }}>
          <div>
            <StyledHintText>{t('minimumAmount')}: {hintsWithMax?.min ?? (hintsWithMax?.fee?.min ?? (hints?.min ?? hints.fee.min))} {hintsWithMax.currency.asset}</StyledHintText>
            <StyledHintText>{t('maximumAmount')}: {hintsWithMax?.max ?? (hintsWithMax?.fee?.max ?? (hints?.max ?? hints.fee.max))} {hintsWithMax.currency.asset}</StyledHintText>
          </div>
          <div style={{ textAlign: "end" }}>
            {hintsWithMax?.commission !== undefined || hints?.commission !== undefined ?
              <StyledHintText>{t("commission")}: {hintsWithMax.commission ?? hints.commission} {hintsWithMax?.currency?.asset ?? hints?.currency?.asset}</StyledHintText> :
              null
            }
            <StyledHintText>{t('yourBalance')}: {hintsWithMax?.balances ? (hintsWithMax?.balances?.amount ?? hints?.balances?.amount) : "0"} {hintsWithMax?.currency?.asset}</StyledHintText>
          </div>
        </StyledFlexAlign>
      }
    </StyledInputGroup>
  );
});

export default InputGroup;
