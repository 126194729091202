import styled from "styled-components";

export const StyledTwoFaForm = styled.form`
  .confirm-action {
    display: grid;
    padding-bottom: 0;
  }
  .twofa-input input {
    text-align: center;
    font-weight: 600;
    letter-spacing: 5px;
  }
`;

export const StyledTwoFaActive = styled.div`
  .security-code {
    text-align: center; 
    background-color: ${({ theme }) => theme.twoFa.codeBg}; 
    padding: 25px 0 25px 0;
    margin-bottom: 20px; 
    
    img {
        padding: 7px;
        background: white;
    }  
  }
`;

export const StyledOrBlock = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
`;

export const StyledOrLine = styled.div`
  height: 2px;
  background-color: ${({theme}) => theme.labelColor};
  width: 100%;  
    
  &:first-child {
    margin-right: 15px;  
  }
    
  &:last-child {
    margin-left: 15px;
  }
`;

export const StyledOrText = styled.span`
  color: ${({theme}) => theme.labelColor};
  flex-shrink: 0;
  text-transform: uppercase;  
`;

export const StyledScrollBlock = styled.div`
    overflow-y: auto;
    max-height: calc(-184px + 100vh);
    padding-right: 20px;
    margin-right: -20px;
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 6px;
        height: 10px;
    }

    &::-webkit-scrollbar-track {
        background-color: ${({theme}) => theme.scrollTrack};
    }

    &::-webkit-scrollbar-thumb {
        height: 8px;
        background-color: ${({theme}) => theme.scrollThumb};

        &:hover {
            background-color: ${({theme}) => theme.scrollThumbHover};
        }
    }
`