import React, { useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { NavLink, useHistory } from "react-router-dom";
import PageSpinner from "../../elements/spinner/PageSpinner";
import { TimestampToDateTimeWithSecondsABC } from "../../../utils/timestampToDate";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
import { StyledDetailsWrapper } from "../styledDetails";
import { StyledCloseImg, StyledContentWrapper } from "../../styles/styledContainer";
import { StyledMainTitle } from "../../styles/styledTitle";
import { StyledAccountActivityItem, StyledBetsWrapper } from "../../account/client/details/styledClientDetails";
import clock from "../../../assets/images/account/clock_icon.svg";
import kubik from "../../../assets/images/account/bets_icon.svg";
import clover from "../../../assets/images/account/clover_icon.svg";
import multiply from "../../../assets/images/account/multiplier_icon.svg";
import percent from "../../../assets/images/account/percent_icon.svg";
import userIcon from "../../../assets/images/no-avatar.svg";
import { generatePath } from "../../../utils/getLanguage";
import { StyledHistoryLink } from "../../styles/styledHistory";
import { AppContext } from "../../../App";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../profileActionsWindow/ProfileActionsStates";
import CheckHonestyControl from "../CheckHonestyControl";

const BetsDiceDetails = ({ bet }) => {
  const history = useHistory();
  const { t } = useTranslation("siteOptions");

  const { user } = useContext(AppContext);

  const {
    handleProfileActionsVisible
  } = useBetween(ProfileActionsStates);

  const goBack = useCallback(() => history.goBack(), [history]);
  // const goToLink = useCallback((link) => history.push(generatePath(link)), [history]);

  if (!bet) {
    return <PageSpinner />;
  }

  return (
    <StyledDetailsWrapper game="dice">
      <StyledContentWrapper width="826" isFlex={true} column={true} position="center">
        <StyledMainTitle className="page-title" position="center" mb="30" mt="1">
          {t("dice")} #{new Intl.NumberFormat("en").format(bet.id)}
          <span className="verified">{t("verified")}</span>
        </StyledMainTitle>
        <StyledCloseImg onClick={goBack} />
        <StyledBetsWrapper>
          <StyledAccountActivityItem className="box-1-3">
            <div className="activity-img">
              <img src={clock} alt="" width={30} />
            </div>
            <b className={"bets-details-title"}>{TimestampToDateTimeWithSecondsABC(bet.createdAt)}</b>
            <span className={"bets-details-description"}>
              {t("date")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-3">
            <div className="activity-img user-avatar">
              <img src={userIcon} className="userIcon" alt="" />
              <div className="level" title={t("accountRating")}>
                ∞
              </div>
            </div>
            <b className={"bets-details-title"}>{
              user?.nickname === bet.user.nickname ?
                <StyledHistoryLink as={NavLink} to={generatePath(`/account/${bet.user.nickname}`)}>
                  <div className={"bet-details"}>
                    {bet.user.nickname}
                  </div>
                </StyledHistoryLink>
                :
                <StyledHistoryLink
                  onClick={() => handleProfileActionsVisible(bet.id, "tips")}
                  data-custom
                  data-custom-at="right"
                  data-id={bet.id}
                  data-nickname={bet.user.nickname}
                  className={"nickname"}
                >
                  {bet.user.nickname}
                </StyledHistoryLink>
            }</b>
            <span>
              {t("player")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-4">
            <div className="activity-img">
              <img src={kubik} alt="" width={30} />
            </div>
            <b className={"bets-details-title"}>{bet.gameObject.sign ? t("moreThan") : t("lessThan")} {bet.suggestedNumbers}</b>
            <span className={"bets-details-description"}>
              {t("betOne")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-4">
            <div className="activity-img">
              <img src={clover} alt="" width={30} />
            </div>
            <b className={"bets-details-title"}>{bet.drawnNumber}</b>
            <span className={"bets-details-description"}>
              {t("drawnNumber")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-3">
            <div className="activity-img currency">
              <StyledPaymentMethodsIcons
                width="50"
                height="50"
                className={`payment-method-big-${bet.paymentMethod.currency.asset}`}
              />
            </div>
            <b className={"bets-details-title"}>{bet.bet}</b>
            <span className={"bets-details-description"}>
              {t("betAmount")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-3">
            <div className="activity-img currency">
              <StyledPaymentMethodsIcons
                width="50"
                height="50"
                className={`payment-method-big-${bet.paymentMethod.currency.asset}`}
              />
            </div>
            <b className={`${bet.win > 0 ? "win" : "lose"} bets-details-title`}>{parseFloat(bet.lose) === 0 ? (parseFloat(bet.bet) * bet.gameObject.coefficient).toFixed(8) : parseFloat("0").toFixed(8)}</b>
            <span className={"bets-details-description"}>
              {t("payout")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-4">
            <div className="activity-img">
              <img src={multiply} className="multiply" alt="" width={30} />
            </div>
            <b className={"bets-details-title"}>{bet.gameObject.coefficient}x</b>
            <span className={"bets-details-description"}>
              {t("multiplier")}
            </span>
          </StyledAccountActivityItem>
          <StyledAccountActivityItem className="box-1-4">
            <div className="activity-img">
              <img src={percent} className="percent" alt="" width={30} />
            </div>
            <b className={"bets-details-title"}>{bet.gameObject.chanceToWin}%</b>
            <span className={"bets-details-description"}>
              {t("chance")}
            </span>
          </StyledAccountActivityItem>
        </StyledBetsWrapper>
        <CheckHonestyControl bet={bet}/>
      </StyledContentWrapper>
    </StyledDetailsWrapper>
  );
};

export default BetsDiceDetails;
