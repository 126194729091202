import bgGameLight from "../../../assets/images/backgrounds/bg-game__light.jpg";
import bgGameMobileLight from "../../../assets/images/backgrounds/bg-game-mobile__light.png";
import bgTemplatePageLight from "../../../assets/images/backgrounds/bg-template__light.jpg";
import bgBallsLight from "../../../assets/images/backgrounds/bg-balls__light.png";
import bgBallsMobileLight from "../../../assets/images/backgrounds/bg-balls-mobile__light.png";
import bgRouletteLight from "../../../assets/images/backgrounds/bg-roulette__light.jpg";
import bgRouletteMobileLight from "../../../assets/images/backgrounds/bg-roulette-mobile__light.png";
import bgDiceHistoryLight from "../../../assets/images/backgrounds/bg-dice-history__lignt.jpg";
import bgRouletteHistoryLight from "../../../assets/images/backgrounds/bg-roulette-history__light.jpg";
import bgBallsHistoryLight from "../../../assets/images/backgrounds/bg-balls-history__light.png";
import rollLight from "../../../assets/images/roll/roll__light.png";
import gamesDiceLight from "../../../assets/images/leftSidebar/games/dice__light.webp";
import gamesRouletteLight from "../../../assets/images/leftSidebar/games/roulette__light.webp";
import gamesBallsLight from "../../../assets/images/leftSidebar/games/balls__light.webp";
import gamesBallsMagnetLight from "../../../assets/images/balls/magnet__light.png";
import gamesBallsItemBgLight from "../../../assets/images/balls/balls__light.png";
import gamesBallsMobileItemBgLight from "../../../assets/images/balls/balls-mobile__light.png";
import gamesBallsItemBgLightActive from "../../../assets/images/balls/balls__light_active.png";
import bgIdDiceLight from "../../../assets/images/backgrounds/pagebg-dice-light.png";
import bgIdBallsLight from "../../../assets/images/backgrounds/pagebg-balls-light.png";
import bgIdRouletteLight from "../../../assets/images/backgrounds/pagebg-roulette-light.png";
import bgCheckBoxRadio from "../../../assets/images/checkboxradio__light.png";
import jackpotDice from "../../../assets/images/jackpot/jackpot-dice.svg";
import jackpotBalls from "../../../assets/images/jackpot/jackpot-balls.svg";
import jackpotRoulette from "../../../assets/images/jackpot/jackpot-roulette.svg";
import settingsIconLight from "../../../assets/images/buttonIcons/settings-icon-light.svg";
import settingsIconDark from "../../../assets/images/buttonIcons/settings-icon-dark.svg";
import headerMailImg from "../../../assets/images/chat/messages_icon_light.svg";
import headerUsersImg from "../../../assets/images/chat/friends_icon_light.svg";
import userTagImg from "../../../assets/images/user_icon_light.svg";
import betTagImg from "../../../assets/images/bet_icon_light.svg";
import miningTimeImg from "../../../assets/images/mining/timer-bl.png";
import miningInviteImg from "../../../assets/images/mining/ppl-wh.png";
import addToFriendsImg from "../../../assets/images/account/add_friend_icon_light.svg";
import sendMessageImg from "../../../assets/images/account/messages_icon_light.svg";
import sendTipImg from "../../../assets/images/account/sendtips_icon_light.svg";
import { StyledPopupAuthenticator } from "../../elements/leftSidebar/TokenBonus/styledTokenBonus";

export default {
  mainTextColor: "#555555",
  secondTextColor: "#555555",
  subTitleColor: "#333333",
  defaultChangeColor: "#058bd8",
  body: "#F0F0F0",
  text: "#000000",
  headerBg: "#222222",
  footerBg: "#222222",
  footerColor: "#C0C0C0",
  neutralColor: "#777777",
  neutralColorHover: "#888888",
  scrollBackground: "#999999",
  scrollTrack: "#c9c9c9",
  scrollThumb: "#999999",
  scrollThumbHover: "#777777",
  optionButtonsBg: "#999999",
  optionOpacity: "1",
  optionOpacityHoverContainer: "1",
  optionBackgroundHover: "#aaaaaa",
  historyLink: "#333333",
  historyLinkHover: "#058bd8",
  optionButtonsBgHover: {
    "background-color": "#aaaaaa;"
  },
  optionButtonsColor: "#FFFFFF",
  elementBg: "#F5F5F5",
  grayForHeader: "#ffffff",
  switchTheme: "center left",
  jackpot: {
    "filter": " brightness(9);",
    "opacity": "0.5;"
  },
  paymentMethodSearch: {
    borderColor: "#d0d0d0",
    color: "#777777",
    opacity: "1",
    mobileNav: {
      backgroundColor: "#ffffff",
      color: "#000000"
    }
  },
  paymentMethodConfig: {
    background: `#999999 url(${settingsIconLight}) no-repeat 50% 50%`,
    opacity: 0.6
  },

  paymentMethodConfigMobile: {
    background: `transparent url(${settingsIconDark}) no-repeat 50% 50%`,
    opacity: 0.6
  },
  paymentMethodNothingFound: {
    backgroundColor: "#eaeaea",
    color: "#555555"
  },
  paymentMethod: {
    "opacity": "1;",
    "font-weight": "500;",
    "color": "#333333;"
  },
  paymentMethodMobileBgColor: "#FFFFFF",
  paymentMethodActive: {
    "background-color": "#e0e0e0;",
    "opacity": "1;"
  },
  paymentMethodHover: {
    "background-color": "#EBEBEB;",
    "opacity": "1;"
  },
  currenciesSelect: {
    "background-color": "#fff;",
    "opacity": "1;"
  },
  currenciesOption: {
    "color": "#333333;"
  },
  //logo
  logo: {
    "opacity": "1;"
  },
  logoHover: {
    "opacity": "1;"
  },
  //sendTip
  sentTipPaymentItem: {
    backgroundColor: "#d4d4d4",
    opacity: 0.5
  },
  sentTipPaymentItemActive: {
    borderBottom: "1px #058bd8 solid",
    background: "#e5e5e5",
    opacity: 1
  },
  //betActions
  betActions: {
    "background-color": "#aaaaaa;"
  },
  //siteOptions
  grayColorText: "#333333",
  //account
  accountTextShadow: "none",
  accountAvatarBorderColor: "#dddddd",
  accountAvatarShadow: "3px 3px 5px rgba(0, 0, 0, 0.3)",
  accountInfoFooterColor: "#e9e9e9",
  accountActivityItemBg: "#f6f6f6",
  accountActivityItemTextColor: "#555555",
  accountActivityRowBg: "#ececec",
  accountActivityRowBorder: "1px #d0d0d0 solid",
  accountActivityItemShadow: "0px 2px 3px rgb(0, 0, 0, 0.3)",
  accountActivityImgShadow: "2px 3px 5px rgba(0,0,0,0.3)",
  accountImages: {
    addToFriends: addToFriendsImg,
    sendMessage: sendMessageImg,
    sendTip: sendTipImg
  },
  accountFriends: {
    backgroundColor: "#ececec",
    border: "1px #d0d0d0 solid",
    item: {
      level: {
        backgroundColor: "#ffffff",
        color: "#333333",
        border: "2px solid #bbbbbb",
        boxShadow: "-1px 1px 5px rgba(0, 0, 0, 0.3)"
      }
    },
    title: {
      color: "#555555",
      counterColor: "#333333"
    }
  },
  //chat
  colorsChat: {
    backgroundColor: "#f0f0f0",
    backgroundColorBot: "#e5e5e5",
    bgColorBotTitle: "#ff9001",
    colorMessage: "#555555",
    colorTime: "#555555",
    colorUser: "#024A74",
    borderColor: "#d0d0d0",
    backgroundEmoji: "#f0f0f0",
    backgroundInfo: "#f0f0f0",
    buttonBackground: "#999999",
    backgroundInputText: "#ffffff",
    tag: {
      color: "#555555",
      hoverColor: "#058bd8",
      userImage: `url(${userTagImg}) no-repeat center center`,
      betImage: `url(${betTagImg}) no-repeat center center`,
      currentColor: "#2eab5b",
      botName: "#555555"
    }
  },
  autoBet: {
    bgModal: "#f9f9f9",
    slider: "#ffffff",
    point: "#aaaaaa",
    backgroundInfo: "#e9e9e9",
  },
  //gamesDice
  diceBox: {
    "color": "#676767",
    "background-color": "rgba(255, 255, 255, 0.8)",
    "border": "5px solid #d0d0d0"
  },
  diceBoxShadow: {
    "background": "rgba(0, 0, 0, .2);",
    "box-shadow": "0px 0 15px 10px rgb(0 0 0 / 20%);"
  },
  rollTableShadow: "0px 0 20px 10px rgba(0, 0, 0, .2)",
  rollTableShadowBackground: "rgba(0, 0, 0, .2)",
  rollMobileTableShadow: "0px 0 20px 10px rgba(0, 0, 0, .2)",
  rollMobileTableShadowBackground: "rgba(0, 0, 0, .2)",
  rollShadow: "0px 0 20px 10px rgba(0, 0, 0, .2)",
  rollShadowBackground: "rgba(0, 0, 0, .2)",
  rollMobileShadow: "0px 0 20px 10px rgba(0, 0, 0, .2)",
  rollMobileShadowBackground: "rgba(0, 0, 0, .2)",
  diceBoxLine: "#d0d0d0",
  diceHistory: {
    progressBg: "#d4d4d4",
    numBg: "#11492f",
    numColor: "#FFFFFF",
    win: "#2eab5b",
    lose: "#d3273f"
  },
  diceNumber: {
    win: "#2eac5b",
    lose: "#ab2e40",
    backgroundColor: "rgba(255, 255, 255, 0.8)"
  },
  //gamesBalls
  ballsMagnet: gamesBallsMagnetLight,
  ballsItem: gamesBallsItemBgLight,
  ballsMobileItem: gamesBallsMobileItemBgLight,
  ballsItemActive: gamesBallsItemBgLightActive,
  ballsBox: {
    "color": "#676767",
    "background-color": "#ffffff",
    "border": "5px solid #d0d0d0"
  },
  ballsBoxLine: "#d0d0d0",
  //roulette
  roll: rollLight,
  rollTableBackground: "rgba(200, 200, 200, 0.4)",
  rollTableBorder: "#f0f0f0",
  rollTableHover: "rgba(150, 150, 150, 0.6)",
  rollTableSplitHover: "rgba(191, 191, 191, 0.75)",
  rouletteBackDown: "rgba(180, 180, 180, 0.4)",
  rouletteRowBackDown: "rgba(200, 200, 200, 0.4)",

  rollTableItemInnerDivRedBackground: "#e21f3a",
  rollTableItemInnerDivBlackBackground: "#000000",
  //LeftSidebar
  leftSidebar: {
    blockTitleColor: "#222222",
    blockBgColor: "#f9f9f9",
    blockBgLightColor: "#e9e9e9",
    blockBorderLightColor: "#d0d0d0",
    blockBorderColor: "#d0d0d0",
    labelStatistic: "#676767",
    statisticBlock: {
      backgroundColor: "#f0f0f0"
    },
    closeBtn: {
      color: "#333333"
    },
    input: {
      backgroundColor: "#ffffff",
      color: "#333333",
      borderBottom: "1px solid #d0d0d0"
    }
  },
  customSelect: {
    blockBorderColor: "#d0d0d0",
    blockHover: "#f0f0f0",
    bgList: "#ffffff",
    arrow: "#777777"
  },
  leftSidebarContainer: {
    "background-color": "#f9f9f9",
    "box-shadow": "0 4px 8.5px 0.5px rgb(46 46 46 / 34%)"
  },
  leftSidebarActions: {
    bgColor: "rgba(4, 4, 4, 0.7)",
    hoverBgColor: "#3B3B3B",
    opacityIcon: "1",
    hoverOpacityIcon: "1"
  },
  leftSidebarGames: {
    dice: gamesDiceLight,
    roulette: gamesRouletteLight,
    balls: gamesBallsLight,
    titleColor: "#535354",
    titleGradientColor: "linear-gradient(0deg, #101413, #535354)"
  },
  leftSidebarChat: {
    header: {
      borderColor: "#d0d0d0"
    },
    headerActionItem: {
      borderColor: "#f9f9f9"
    },
    headerMailImg: headerMailImg,
    headerUsersImg: headerUsersImg,
    lang: {
      backgroundColor: "#777777",
      color: "#ffffff"
    }
  },
  leftSidebarRooms: {
    item: {
      backgroundColor: "#f0f0f0",
      color: "#333333"
    },
    itemHover: {
      backgroundColor: "#e0e0e0"
    }
  },
  leftHonestyControl: {
    linkHoverColor: "#307cad"
  },
  readingPage: {
    bgColor: "#F7F7F7",
    color: "#555555",
    borderColor: "#E2E2E2"
  },
  //Jackpot
  scrollbar: "#b1b1b1",
  scrollbarThumb: "#d5d5d5",
  diceColor: "#F5F5F5",
  ballsColor: "#F5F5F5",
  rouletteColor: "#F5F5F5",
  blockBgColor: "rgba(229, 229, 229, 0.9)",
  //Input
  inputColor: "#333333",
  inputBgColor: "#ffffff",
  inputBorderColor: "#c9c9c9",
  inputBorderColorDisabled: "#f0f0f0",
  inputBoxShadow: "inset 0 1px 1px rgb(0 0 0 / 20%);",
  labelColor: "#676767",
  readOnlyInput: "#555555",
  //CustomSelectPayment
  customOptionBg: "#ffffff",
  activeBgOption: "#f0f0f0",
  customOptionBorder: "#d0d0d0",
  //AlertMessage
  alertMessageBg: "#e9e9e9",
  dottedAlertBorder: "#d1d1d1",
  //UploadAvatar
  lightBackground: "#d1d1d1",
  borderUploadAvatar: "#8C8C8C",
  opacityUploadAvatar: "0.7",
  //Table
  tableRow: {
    color: "#333333",
    backgroundColor: "#fcfdfc",
    borderColor: "#d4d4d4",
    borderWidth: 0
  },
  partnerItem: {
    backgroundColor: "rgba(20, 20, 20, 0.6)",
    borderColor: "#181818",
  },
  tabs: {
    textColorActive: "#333333"
  },
  lightTableBackground: "#eeeeee",
  //Notification
  notification: {
    bgColor: "#ffffff",
    fontColor: "#333333",
    shadowColor: "0 2px 24px rgb(0 0 0 / 30%)",
    closeBtnColor: "#888888",
    iconColor: {
      error: "#E11F3C",
      success: "#4fba6e",
      warning: "#FEB967",
      info: "#3286d9",
      bgColor: "#F0F0F0"
    }
  },
  //Control
  control: {
    code: {
      fontColor: "#555555",
      fontColorFunc: "#000000"
    }
  },
  authBtn: {
    backgroundColor: "rgb(119, 119, 119)",
    borderColor: "rgb(119, 119, 119)",
    backgroundHover: "rgb(150, 150, 150)",
    borderHover: "rgb(150, 150, 150)"
  },
  //Back checkbox-input
  backgroundCheckboxInput: bgCheckBoxRadio,
  //Backgrounds
  bgGame: bgGameLight,
  bgMobileGame: bgGameMobileLight,
  bgTemplatePage: bgTemplatePageLight,
  bgBalls: bgBallsLight,
  bgBallsMobile: bgBallsMobileLight,
  bgRoulette: bgRouletteLight,
  bgRouletteMobile: bgRouletteMobileLight,
  bgDiceHistory: bgDiceHistoryLight,
  bgRouletteHistory: bgRouletteHistoryLight,
  bgBallsHistory: bgBallsHistoryLight,
  bgIdDice: bgIdDiceLight,
  bgIdBalls: bgIdBallsLight,
  bgIdRoulette: bgIdRouletteLight,
  jackpotDice: jackpotDice,
  jackpotBalls: jackpotBalls,
  jackpotRoulette: jackpotRoulette,
  mining: {
    timeIcon: miningTimeImg,
    inviteImg: miningInviteImg,
    infoBg: "#eaeaea",
    timer: {
      alternateBg: "#dfdfdf",
      defaultBg: "#efefef"
    }
  },
  twoFa: {
    codeBg: "#e9e9e9"
  },
  notifications: {
    button: {
      backgroundColor: "#d9d9d9"
    },
    body: {
      backgroundColor: "#d9d9d9",
      borderColor: "#307DAC"
    },
    time: {
      backgroundColor: "#c0c0c0"
    }
  },
  closeIconColor: "#777777",
  staking: {
    backgroundColor: "#f9f9f9B3",
    infoBlock: {
      backgroundColor: "#fdfdfd",
      mainTextColor: "#555555",
      textColor: "#8c8c8c"
    },
    tabs: {
      textColor: "#777777"
    },
    search: {
      backgroundColor: "#fcfcfc",
      textColor: "#777777"
    },
    table: {
      mainTextColor: "#555555",
      textColor: "#a6a6a6",
      mainTextFontWeight: 400,
      tableRow: {
        backgroundColorEven: "#fdfdfd",
        backgroundColorOdd: "#f9f9f9",
        backgroundColorCollapse: "#eeeeee"
      },
      period: {
        borderColor: "#d0d0d0",
        backgroundColor: "#e9e9e9",
        backgroundColorActive: "#f9fbfd",
        textColor: "#939393"
      },
      timer: {
        backgroundColor: "#dbdbdb"
      }
    },
    progress: {
      backgroundColor: "#ffffff",
      borderColor: "#d0d0d0", // #2f7bad
      progressColor: "#e9f6ff",
      mainTextColor: "#555555",
      textColor: "#939393"
    }
  },
  store: {
    item: {
      backgroundColor: "rgba(200, 200, 200, 0.7)"
    }
  },
  dialogMask: {
    backgroundColor: "rgba(0, 0, 0, 0.3)"
  },
  mobileNav: {
    item: {
      backgroundColor: "#f0f0f0",
      color: "#333333"
    },
    itemHover: {
      backgroundColor: "#e0e0e0",
      color: "#333333"
    },
    closeBtn: {
      backgroundColor: "#757575"
    },
    navBlock: {
      opacity: 1,
      backgroundColor: "#4f4f4f",
      bgHover: "#676767",
      textOpacity: 1,
      opacityHover: 1,
    },
    mobNavBlock: {
      opacity: 0.6,
      backgroundColor: "rgba(0, 0, 0, 0.4)",
      bgHover: "rgba(0, 0, 0, 0.5)",
      textOpacity: 1,
      opacityHover: 1,
    }
  },
  tokens: {
    days: {
      backgroundColor: "#e9e9e9",
      filled: "#dddddd",
      notFilled: "#f9f9f9",
      amountCurrDay: "#333333",
      amountOtherDay: "#aaaaaa",
      headerDayBackground: "linear-gradient(to right, rgb(103, 103, 103) 0%, rgb(221, 221, 221) 50%, rgb(103, 103, 103) 100%)"
    },
    notificationAmountToken: {
      textColor: "#555555",
      backgroundColor: "#e9e9e9",
      iconColor: "#333333",
      iconBackground: "#ffffff"
    },
    popupAuthenticator: {
      backgroundColor: "#e9e9e9",
      textColor: "#696969",
    }
  },
  userLevel: {
    background: "#ececec",
    color: "#555555",
    starBg: "#2eab5b",
    levelColor: "#555555",
    desColor: "#555555",
    border: "#d0d0d0"
  },
  userLevelItem: {
    border: "#d0d0d0",
    background: "#ececec",
    titleColor: "#333333",
    pointsColor: "#555555",
    percentBg: "#f9f9f9",
    percentLine: "#333333",
    percentColor: "#2eab5b",
    descColor: "#555555"
  },
  accountSettingLink: {
    color: "#058bd8"
  },
  transactionHist: {
    nothingBg: "rgba(255, 255, 255, 0.6)",
    nothingTitle: "#555555",
    itemBg: "rgba(255, 255, 255, 0.6)",
    itemColor: "#676767",
    itemTitle: "#333333",
    infoBg: "#e5e5e5",
    infoTitle: "#333333",
    infoSp: "#333333",
    infoBd: "#111111",
    red: "#d3273f"
  },
  bonusGame: {
    titleForm: "#000000",
    textSec: "#000000",
    textMain: "#333333",
    link: "#676767",
    tableItem: "#98b7ad",
    blockBg: "rgba(229, 229, 229, 0.9)"
  },
  bonusRules: {
    background: "#e9e9e9",
    limitText: "#676767"
  }
};
