import React, {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import axios from "axios";
import {AppContext} from "../../../App";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import {closableNotification} from "../notification/ClosableNotification";
import {responseStatus} from "../../../utils/consts";
import InputGroup from "../inputGroup/InputGroup";
import {StyledButton} from "../../styles/styledButton";
import {StyledOrBlock, StyledOrLine, StyledOrText, StyledScrollBlock, StyledTwoFaActive} from "./styledTwoFa";
import AlertMessage from "../alert/Alert";
import http from "../../../http";
import Spinner from "../spinner/Spinner";
import ReCaptcha from "../reCaptcha/ReCaptcha";
import {generatePath} from "../../../utils/getLanguage";
import {NavLink} from "react-router-dom";

const TwoFaModal = ({visible, setVisible, setTwoFa, twoFaIsActive}) => {

    const {user} = useContext(AppContext);

    const recaptchaRef = useRef(null);

    const [authenticatorSecret, setAuthenticatorSecret] = useState(false);
    const [codeActive, setCodeActive] = useState("");
    const [codeRemove, setCodeRemove] = useState("");
    const [codeSecretRemove, setCodeSecretRemove] = useState("");
    const [loading, setLoading] = useState(true);
    const [isLoadingBtn, setIsLoadingBtn] = useState(false);

    const {t} = useTranslation("siteOptions");
    const {t: tE} = useTranslation('errors');

    useEffect(() => {
        checkTwoFaIsActive();
    }, []);

    const checkTwoFaIsActive = () => {
        http.get("/api/users/authenticator-secret-is-active/" + user.userId, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setTwoFa(response.data.isActive);
                setLoading(false);
            }
        }).catch(error => {
            closableNotification(error.response.data.detail, "error");
            setLoading(false);
        });
    };

    const getAuthenticatorSecretUser = () => {
        axios.get("/api/users/authenticator-secret/" + user.userId, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setAuthenticatorSecret(response.data);
            }
        }).catch(error => {
            closableNotification(tE(error.response.data.error), "error");
        });
    };

    const setAuthenticatorSecretUser = (code) => {
        if (isLoadingBtn) return;
        setIsLoadingBtn(true);
        let data = {
            ...authenticatorSecret,
            code,
            twoFaIsActive
        };

        axios.put("/api/users/authenticator-secret/" + user.userId, data, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setCodeActive("");
                closableNotification(t("twoFaEnabled"), "success");
                setVisible("");
                setTwoFa(true);
                setIsLoadingBtn(false);
            }
        }).catch(error => {
            closableNotification(tE(error.response.data.error), "error");
            setIsLoadingBtn(false);
        });
    };

    const removeAuthenticatorSecretUser = (code) => {
        if (isLoadingBtn) return;
        setIsLoadingBtn(true);
        const captchaToken = recaptchaRef?.current?.getValue();
        if (!captchaToken) {
            setIsLoadingBtn(false);
            return closableNotification(tE("Failed authentication, suspicious activity"), "error");
        }
        recaptchaRef.current.reset();

        let data = {
            code,
            twoFaIsActive,
            captchaToken
        };
        console.log(data)

        axios.put("/api/users/authenticator-secret-remove/" + user.userId, data, userAuthenticationConfig()).then(response => {
            if (response.status === responseStatus.HTTP_OK) {
                setCodeRemove("");
                closableNotification(t("twoFaDisabled"), "success");
                setVisible("");
                setTwoFa(false);
                setIsLoadingBtn(false);
            }
        }).catch(error => {
            closableNotification(tE(error.response.data.error), "error");
            setIsLoadingBtn(false);
        });
    };

    useEffect(() => {
        if (visible) {
            getAuthenticatorSecretUser();
        }
    }, [visible]);


    return (
        <>
            <div className="default-modal__content">
                {loading && <Spinner display="block" size="35px"/>}
                {!twoFaIsActive && !loading && (
                    <StyledTwoFaActive>
                        <StyledScrollBlock>
                            <InputGroup
                                id="twoFaCode"
                                label={t("inputSeKey")}
                                name="twoFaCode"
                                value={authenticatorSecret.tempSecret}
                                type="text"
                                maxLength="6"
                                autocomplete="off"
                            />
                            <div className="security-code">
                                <img
                                    src={authenticatorSecret.tempQRCode}
                                    alt="secret"
                                />
                            </div>
                            <InputGroup
                                id="twoFaCode"
                                label={t("inputKey")}
                                name="twoFaCode"
                                value={codeActive}
                                handleChange={(event) => setCodeActive(event.target.value)}
                                type="text"
                                maxLength="6"
                                placeholder={t("inputKeyP")}
                                style={{marginBottom: "10px"}}
                                autocomplete="off"
                            />
                            <AlertMessage>
                                {t('alertKeyOne')}&nbsp;<a target="_blank"
                                                           href="https://support.google.com/accounts/answer/1066447"
                                                           className="default-link gray"
                            >Google Authenticator</a>.&nbsp;{t('alertKeyTwo')}
                            </AlertMessage>
                        </StyledScrollBlock>
                        <div className="default-modal__footer security-code-action">
                            <StyledButton
                                color="neutral"
                                id={"set"}
                                onClick={() => setAuthenticatorSecretUser(codeActive)}
                                style={{marginTop: "0"}}
                            >
                                {!isLoadingBtn ? t('enable2FA') :
                                    <div style={{textAlign: "left", margin: "-7.2855px -10px"}}><Spinner display="block"
                                                                                                         size="16px"/>
                                    </div>}
                            </StyledButton>
                        </div>
                    </StyledTwoFaActive>
                )}
            </div>

            {twoFaIsActive && !loading && (
                <div>
                    <StyledScrollBlock>
                        <InputGroup
                            id="twoFaCodeRemove"
                            label={t("inputKey")}
                            name="twoFaCodeRemove"
                            value={codeRemove}
                            handleChange={(event) => setCodeRemove(event.target.value)}
                            type="text"
                            maxLength="6"
                            placeholder={t("inputKeyP")}
                            autocomplete="off"
                        />
                        <StyledOrBlock>
                            <StyledOrLine/>
                            <StyledOrText>{t("or")}</StyledOrText>
                            <StyledOrLine/>
                        </StyledOrBlock>
                        <InputGroup
                            id="twoFaSecretCodeRemove"
                            label={t("inputSKey")}
                            name="twoFaSecretCodeRemove"
                            value={codeSecretRemove}
                            handleChange={(event) => setCodeSecretRemove(event.target.value)}
                            type="text"
                            maxLength="25"
                            placeholder={t("inputSKeyP")}
                            style={{marginBottom: "-5px"}}
                            autocomplete="off"
                        />
                        <ReCaptcha recaptchaRef={recaptchaRef}/>
                        <AlertMessage
                            message={twoFaIsActive ? t('disabledAlert') : `${t('alertKeyOne')} Google Authenticator. ${t('alertKeyTwo')}`}/>
                    </StyledScrollBlock>
                    <div className="default-modal__footer security-code-action">
                        <StyledButton
                            color="neutral"
                            id={"remove"}
                            onClick={() => removeAuthenticatorSecretUser(codeRemove.length === 0 ? (codeSecretRemove.length === 0 ? {} : {codeSecret: codeSecretRemove}) : {code: codeRemove})}
                            style={{marginTop: "0"}}
                        >
                            {!isLoadingBtn ? t('disable2FA') :
                                <div style={{textAlign: "left", margin: "-7.2855px -10px"}}><Spinner display="block"
                                                                                                     size="16px"/>
                                </div>}
                        </StyledButton>
                    </div>
                </div>
            )}
        </>
    );
};

export default TwoFaModal;