import { StyledLabel } from "../elements/inputGroup/styledInputGroup";
import { StyledPaymentMethodItem, StyledWrapperPaymentItem } from "./styledProfileActions";
import { StyledPaymentMethodsIcons } from "../styles/StyledPaymentMethodsIcons";
import InputGroup from "../elements/inputGroup/InputGroup";
import BetAction from "../elements/betAction/BetAction";
import AlertMessage from "../elements/alert/Alert";
import { StyledButton } from "../styles/styledButton";
import Dialog from "rc-dialog";
import React, {useContext, useEffect, useRef, useState} from "react";
import { useBetween } from "use-between";
import ProfileActionsStates from "./ProfileActionsStates";
import BalanceStates from "../games/BalanceStates";
import { useTranslation } from "react-i18next";
import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import { responseStatus } from "../../utils/consts";
import { closableNotification } from "../elements/notification/ClosableNotification";
import http from "../../http";
import ReCaptcha from "../elements/reCaptcha/ReCaptcha";
import {AppContext} from "../../App";

const SendTipWindow = ({ activeNickname, room }) => {
  const { t } = useTranslation("siteOptions");

  const {
    visibleTipWindow,
    setVisibleTipWindow
  } = useBetween(ProfileActionsStates);

  const {
    paymentMethods,
    setBalance,
    setPaymentMethods,
    balance
  } = useBetween(BalanceStates);

  const [activePaymentInfo, setActivePaymentInfo] = useState(JSON.parse(localStorage.getItem("paymentMethod")) ?
    JSON.parse(localStorage.getItem("paymentMethod")) :
    paymentMethods[0]);

  const [valueNumber, setValueNumber] = useState("0.000000000");
  const recaptchaRef = useRef(null);
  const [codeActive, setCodeActive] = useState("");
  const { twoFaIsActive } = useContext(AppContext);

  const selectPaymentMethod = (value) => {
    localStorage.setItem("paymentMethod", JSON.stringify(value));

    setActivePaymentInfo(value);
    setBalance(value.balances ? value.balances.amount : 0);
  };

  useEffect(() => {
    if (paymentMethods.length > 0 || !visibleTipWindow) return;
    getPaymentMethodsSystem();
  }, [visibleTipWindow]);

  const trimToEightDecimals = (num) => {
    const str = num.toString();
    const decimalIndex = str.indexOf('.');
    if (decimalIndex === -1) return str;
    return parseFloat(str.slice(0, decimalIndex + 9));
  };

  const formatErrorMessage = (errorMessage) => {
    const regex = /Minimum (\d+\.\d+), Maximum (\d+\.\d+)/;
    const match = errorMessage.match(regex);

    if (!match) {
      return errorMessage;
    }

    const min = parseFloat(match[1]);
    const max = parseFloat(match[2]);

    if (!activePaymentInfo || paymentMethods) return t('errorLimitCurrency')

    const newActivePaymentInfo = {
      ...activePaymentInfo,
      minTip: trimToEightDecimals(min),
      maxTip: trimToEightDecimals(max)
    }

    localStorage.setItem("paymentMethod", JSON.stringify(newActivePaymentInfo));
    setActivePaymentInfo(newActivePaymentInfo)
    setPaymentMethods(prevMethods => {
      return prevMethods.map(method =>
          method.id === activePaymentInfo.id
              ? { ...method, minTip: newActivePaymentInfo.minTip, maxTip: newActivePaymentInfo.maxTip } : method);
    });

    return t('errorLimitCurrency');
  };

  const getPaymentMethodsSystem = () => {
    http.get(`/api/payment-methods`, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);
      }
    }).catch(error => {
      closableNotification(error.response.data.error, "error");
    }).finally(() => {
    });
  };

  const sendTip = (captchaToken) => {
    let data = {
      recipientNickname: activeNickname,
      currency: activePaymentInfo.currency.asset,
      value: valueNumber.toString(),
      room: room ?? null,
      code: codeActive ?? null,
      captchaToken
    };

    http.post("/api/tips", data, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        closableNotification("Tip successfuly sent", "success");
        setVisibleTipWindow(false);

      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST || error.response.status ===
        responseStatus.HTTP_ERROR_VALIDATION || error.response.status === responseStatus.HTTP_UNAUTHORIZED) {
        closableNotification(formatErrorMessage(error.response.data.error), "error");
      }

      if (error.response.status === responseStatus.HTTP_UNAUTHORIZED) {
        closableNotification(error.response.data.detail, "error");
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    sendTip(captchaToken);
  };

  const handleInput = (valueNumber) => {
    const filteredValue = valueNumber.replace(/[^0-9.]/g, '');

    const value = filteredValue.replace(/,/g, '.');

    if (!isNaN(value) && (value.match(/\./g) || []).length <= 1) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart && decimalPart.length > 8) {
        decimalPart = decimalPart.substring(0, 8);
      }
      setValueNumber(decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart);
    } else {
      setValueNumber(value);
    }
  };

  return (
    <Dialog
      visible={visibleTipWindow}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisibleTipWindow(false)}
      animation="zoom"
      maskAnimation="fade"
      title={`${t("sendTipTo")}${activeNickname}`}
      forceRender={false}
      className="default-modal"
      style={{margin: "auto"}}
    >
      <StyledLabel>{t("chooseTheCoin")}:</StyledLabel>
      {
        paymentMethods && (
          <StyledWrapperPaymentItem>
            {
              paymentMethods.length !== 0 ? paymentMethods.map((value, key) => (
                <StyledPaymentMethodItem
                  key={key}
                  onClick={() => selectPaymentMethod(value)}
                  className={`payment-method-item ${activePaymentInfo?.currency?.asset === value.currency.asset ? "active" : ""}`}
                  style={{width: "44px", height: "44px"}}
                >
                  <StyledPaymentMethodsIcons className={`payment-method-${value.currency.asset}`}/>
                </StyledPaymentMethodItem>
              )) : <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>
            }
          </StyledWrapperPaymentItem>
        )
      }
      <InputGroup
        autocomplete="off"
        id="amount"
        type="text"
        label={t("enterSum")}
        name="amount"
        value={valueNumber}
        betAction={
          <BetAction
            value={valueNumber}
            setValue={setValueNumber}
            min={activePaymentInfo?.minTip}
            max={parseFloat(activePaymentInfo?.balances?.amount ?? 0) < activePaymentInfo?.maxTip ? parseFloat(activePaymentInfo?.balances?.amount ?? 0) : activePaymentInfo?.maxTip}
          />}
        hintsWithMax={{
          ...activePaymentInfo,
          fee: {
            ...activePaymentInfo,
            min: activePaymentInfo?.minTip?.toFixed(8),
            max: activePaymentInfo?.maxTip?.toFixed(8)
          }
        }}
        handleInput={handleInput}
      />
      {twoFaIsActive ? <InputGroup
          id="twoFaCode"
          label={t("inputKey")}
          name="twoFaCode"
          value={codeActive}
          handleChange={(event) => setCodeActive(event.target.value)}
          type="text"
          maxLength="6"
          placeholder={t("inputKeyP")}
          autocomplete="off"
      /> : null}
      <ReCaptcha recaptchaRef={recaptchaRef}/>
      <AlertMessage
          type="warning"
          message={t("weRecommendSettingUp")}
          mt="20"
          mb="20"
      />
      <div className="confirm-action">
        <StyledButton
          color="neutral"
          type="submit"
          width="100"
          onClick={handleSubmit}
        >{t("confirmTip")}</StyledButton>
      </div>
    </Dialog>
  );
};

export default SendTipWindow;
