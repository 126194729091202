import React, { useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState, } from "react";
import { useBetween } from "use-between";
import { useTranslation } from "react-i18next";
import Dialog from "rc-dialog";
import InputGroup from "../elements/inputGroup/InputGroup";
import { closableNotification } from "../elements/notification/ClosableNotification";

import userAuthenticationConfig from "../../utils/userAuthenticationConfig";
import { defaultCurrentStatistics, responseStatus, TOPICS_LIST } from "../../utils/consts";
import { StyledButton } from "../styles/styledButton";
import {
  StyledBalanceWrapper,
  StyledNothingFound,
  StyledPaymentMethodItem,
  StyledPaymentMethodList,
  StyledPaymentMethodWrapper,
  StyledSearchWrapper
} from "./styledPaymentMethod";
import { StyledPaymentMethodsIcons } from "../styles/StyledPaymentMethodsIcons";
import PaymentInvoice from "../elements/payment/PaymentInvoice";
import PayoutInvoice from "../elements/payout/PayoutInvoice";
import Balance from "./Balance";
import BalanceStates from "./BalanceStates";
import BurgerStates from "../elements/mobileNavigation/BurgerStates";
import { AppContext, MercureUrl } from "../../App";
import { useAutoBetsStatesRoulette } from "../elements/autoBets/AutoBetsStates";
import { generateJWSToken } from "../../utils/mercureAuth";
import Cookies from "js-cookie";
import http from "../../http";
import CoinConfig from "../elements/coinConfig/CoinConfig";
import CurrentStatisticStates from './CurrentStatisticStates';
import { useAnimationSyncData } from './AnimationSyncDataStates';
import scrollIntoView from "scroll-into-view-if-needed";

const RoulettePaymentMethodContainer = React.memo(React.forwardRef(({
  paymentMethod,
  submitData,
  setSubmitData,
  responseData,
  isEnd,
  isNavigation = false,
  isRoulette = false,
  animationDisabled = false,
  isMobile,
  game,
}, ref) => {

  const {
    paymentMethods,
    setPaymentMethods,
    balance,
    setBalance,
    activeCurrency,
    setActiveCurrency,
    paymentDialogVisible,
    setPaymentDialogVisible,
    payoutDialogVisible,
    setPayoutDialogVisible,
    balanceSync,
    setBalanceSync,
    coinConfigDialogVisible,
    setCoinConfigDialogVisible,
    coinConfig,
    setCoinConfig
  } = useBetween(BalanceStates);

  const { setCurrency } = useBetween(CurrentStatisticStates);

  const {
    balanceRef,
  } = useBetween(BurgerStates);

  const balanceInputRef = useRef();
  const lastRespIdRef = useRef(null);
  const paymentMethodListRef = useRef(null);

  const { authenticated } = useContext(AppContext);
  // const [balanceSync, setBalanceSync] = useState(balance);

  const [sortPaymentMethods, setSortPaymentMethods] = useState(paymentMethods);
  const [valueSearch, setValueSearch] = useState("");
  const [profit, setProfit] = useState({
    win: null,
    lose: null
  });

  const [isNothingFound, setIsNothingFound] = useState(false);

  const { autoModeIsStart, autoMode } = useAutoBetsStatesRoulette();

  const autoModeAcceleration = useMemo(() => autoMode.acceleration, [autoMode]);

  const { t } = useTranslation("games");
  const { t: sT } = useTranslation("siteOptions");
  const { t: lT } = useTranslation("leftSidebar");

  useImperativeHandle(ref, () => ({
    getBalanceInputBoundingClientRect: (val) => {
      const balanceCurrent = !isMobile ? balanceInputRef.current : balanceRef.current;
      if (!isMobile) {
        balanceCurrent.value = `${Number(val).toFixed(8)}`;
      } else balanceCurrent.textContent = `${Number(val).toFixed(8)}`;
      return balanceCurrent.getBoundingClientRect();
    },
    getBalanceInputValue: () => {
      const balanceCurrent = !isMobile ? balanceInputRef.current : balanceRef.current;
      return !isMobile ? balanceCurrent.value : balanceCurrent.textContent;
    },
    setBalanceInputValue: (val) => {
      const balanceCurrent = !isMobile ? balanceInputRef.current : balanceRef.current;
      if (!isMobile) {
        balanceCurrent.value = `${Number(val).toFixed(8)}`;
      } else balanceCurrent.textContent = `${Number(val).toFixed(8)}`;
    },
    updatBalanceInputValue: (val, duration, oldBalance) => new Promise((resolve) => {
      const balanceCurrent = !isMobile ? balanceInputRef.current : balanceRef.current;
      const currentVal = oldBalance ? oldBalance : parseFloat(!isMobile ? balanceCurrent.value : balanceCurrent.textContent);
      const delta = parseFloat(`${val}`) - currentVal;

      if (delta === 0) {
        resolve();
        return;
      }
      if (duration === 0) {
        if (!isMobile) {
          balanceCurrent.value = `${Number(val).toFixed(8)}`;
        } else balanceCurrent.textContent = `${Number(val).toFixed(8)}`;
        resolve();
        return;
      }
      const dt = Math.min(40, duration); // Math.max(1, Math.round(duration / Math.abs(delta)));
      let t = 0;
      let time = 0;
      let intervalId = setInterval(() => {
        time += dt;
        t = Math.min(1, time / duration);
        if (!isMobile) {
          balanceCurrent.value = `${Number(currentVal + (delta * t)).toFixed(8)}`;;
        } else balanceCurrent.textContent = `${Number(currentVal + (delta * t)).toFixed(8)}`;;
        if (t >= 1) {
          clearInterval(intervalId);
          intervalId = 0;
          resolve();
        }
      }, dt);
    }),
  }), [balanceInputRef, balanceRef, isMobile]);

  const coinConfigDialogToggle = useCallback(() => {
    setCoinConfigDialogVisible(oldState => !oldState);
  }, [setCoinConfigDialogVisible]);

  const filteredPaymentMethods = useMemo(() => {
    const filteredMethods = sortPaymentMethods.filter(((item, index, self) => {
      if (coinConfig?.assetsVisibility === 'nonzero') {
        return item.balances ? item.balances.amount > 0 : false;
      }
      return self.findIndex(v => v.currency['@id'] === item.currency['@id']) === index;
    }));
    setIsNothingFound(filteredMethods.length === 0)
    return filteredMethods;
  }, [sortPaymentMethods, coinConfig]);

  const getItemName = useCallback((item) => {
    if (coinConfig?.coinShownNames === 'balance') {
      return item.balances?.amount ?? '0.00000000';
    }
    return item.currency.name;
  }, [coinConfig?.coinShownNames]);

  const handleSearch = useCallback((e) => {
    const { value } = e.target;
    let resultSort = paymentMethods.filter((item) => item.currency.name.toLowerCase().includes(value.toLowerCase()) || item.currency.asset.toLowerCase().includes(value.toLowerCase()));
    setSortPaymentMethods(resultSort);
    if (sortPaymentMethods.length && resultSort.length) {
      setIsNothingFound(false);
    } else {
      setIsNothingFound(true);
    }
  }, [paymentMethods, sortPaymentMethods.length]);

  const handleClearSearch = useCallback(() => {
    setValueSearch("");
    setSortPaymentMethods(paymentMethods);
  }, [paymentMethods]);

  const handleFillIn = useCallback(() => {
    if (!paymentMethod) return;
    if (authenticated) {
      setPaymentDialogVisible(true);
    } else {
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPaymentDialogVisible, paymentMethod]);

  const handleBringOut = useCallback(() => {
    if (!paymentMethod) return;
    if (authenticated) {
      setPayoutDialogVisible(true);
    } else {
      closableNotification("Full authentication is required to access this resource.", "error");
    }
  }, [authenticated, setPayoutDialogVisible, paymentMethod]);

  const selectPaymentMethod = useCallback((value) => {
    if (authenticated) {
      localStorage.setItem("paymentMethod", JSON.stringify(value));
      localStorage.setItem("currentStatistics", JSON.stringify(defaultCurrentStatistics));
    }

    console.log('selectPaymentMethod value:', value);
    setCurrency(value.currency.asset);
    setActiveCurrency(value.currency.asset);
    setBalance(value.balances ? value.balances.amount : 0);

    setSubmitData((prevState) => ({
      ...prevState,
      paymentMethod: value
    }));
  }, [authenticated, setActiveCurrency, setBalance, setSubmitData, setCurrency]);

  const renderItemMethod = useMemo(() => {
    return (
      <>
        <StyledSearchWrapper className="coin-search">
          <input
            autoComplete="off"
            type="text"
            className={`coin-search__input ${valueSearch ? "active" : ""}`}
            placeholder={`${t("find")}...`}
            value={valueSearch}
            onChange={(e) => setValueSearch(e.target.value)}
            onKeyUp={handleSearch}
          />
          <div
            className={`coin-search__clean ${valueSearch ? "active" : ""}`}
            onClick={handleClearSearch}
          >✕
          </div>
          <button
            className={'coin-config__button'}
            onClick={coinConfigDialogToggle}
          />
          <Dialog
            visible={coinConfigDialogVisible}
            wrapClassName="default-modal-wrapper"
            onClose={coinConfigDialogToggle}
            animation="zoom"
            maskAnimation="fade"
            title={t("assetsSettingsTitle")}
            forceRender={false}
            className="default-modal"
          >
            <CoinConfig
              coinConfig={coinConfig}
              setCoinConfig={setCoinConfig}
              close={coinConfigDialogToggle}
            />
          </Dialog>
        </StyledSearchWrapper>
        {
          filteredPaymentMethods.length !== 0 ? filteredPaymentMethods.map((value, key) => (
            <StyledPaymentMethodItem
              key={key}
              onClick={() => selectPaymentMethod(value)}
              disabled={autoModeIsStart && autoModeAcceleration > 0}
              className={`payment-method-item ${activeCurrency === value.currency.asset ? "active" : ""}`}
            >
              <div className="payment-method-item__name">
                {getItemName(value)}
              </div>
              <StyledPaymentMethodsIcons className={`payment-method-${value.currency.asset}`}/>
            </StyledPaymentMethodItem>
          )) : isNothingFound ?
            <StyledNothingFound>
              {t("nothingFound")}
            </StyledNothingFound> : <StyledPaymentMethodItem>{t("loading")}</StyledPaymentMethodItem>
        }
      </>
    );
  }, [
    activeCurrency,
    autoModeAcceleration,
    autoModeIsStart,
    coinConfig,
    coinConfigDialogVisible,
    coinConfigDialogToggle,
    filteredPaymentMethods,
    getItemName,
    handleClearSearch,
    handleSearch,
    isNothingFound,
    selectPaymentMethod,
    setCoinConfig,
    t,
    valueSearch,
  ]);

  const getActuallyPaymentData = useCallback((paymentMethodData) => {
    let paymentMethod = JSON.parse(localStorage.getItem("paymentMethod"));

    paymentMethodData.forEach(actuallyPaymentMethod => {
      if (paymentMethod.id === actuallyPaymentMethod.id) {
        paymentMethod.balances = actuallyPaymentMethod.balances;
      }
    });

    return paymentMethod;
  }, []);

  useEffect(() => {
    if (paymentMethodListRef.current && filteredPaymentMethods.length > 0 && paymentMethod) {
      const coinList = paymentMethodListRef.current
      const activeCoin = coinList.querySelector(".active");
      if (activeCoin) {
        scrollIntoView(activeCoin, {
          behavior: 'smooth',
          block: 'center',
          scrollMode: 'if-needed',
          boundary: coinList,
        });
      }
    }
  }, [paymentMethod]);

  const getPaymentMethod = useCallback(() => {
    http.get("/api/payment-methods", userAuthenticationConfig()).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);
        setSortPaymentMethods(response.data["hydra:member"]);

        if (!localStorage.getItem("paymentMethod")) {
          selectPaymentMethod(response.data["hydra:member"][0]);
        } else {
          selectPaymentMethod(getActuallyPaymentData(response.data["hydra:member"]));
        }
      }
    }).catch((error) => {
      if (error?.response?.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    });
  }, [selectPaymentMethod, setPaymentMethods, getActuallyPaymentData]);

  useEffect(() => {
    getPaymentMethod();
  }, [getPaymentMethod]);

  const isDisableSyncBalanceRef = useRef((autoModeIsStart && autoModeAcceleration > 0) || animationDisabled || isEnd || (autoModeIsStart && (animationDisabled || autoModeAcceleration > 0)));
  useEffect(() => {
    isDisableSyncBalanceRef.current = (autoModeIsStart && autoModeAcceleration > 0) || animationDisabled || isEnd || (autoModeIsStart && (animationDisabled || autoModeAcceleration > 0));
  }, [autoModeIsStart, autoModeAcceleration, animationDisabled, isEnd]);

  useEffect(() => {
    if (!paymentMethod?.balances) {
      return;
    }
    if (isNavigation) {
      return;
    }

    const topic = TOPICS_LIST.BALANCES.BALANCE + paymentMethod?.balances.id;
    const token = generateJWSToken(topic);

    MercureUrl.searchParams.delete("topic");

    MercureUrl.searchParams.append("topic", topic);

    Cookies.set("mercureAuthorization", token, { path: "" });

    const es = new EventSource(MercureUrl, { withCredentials: true });

    es.addEventListener("message", (event) => {
      let dataMercure = JSON.parse(event.data ?? null);
      if (isDisableSyncBalanceRef.current) {
        setBalance(dataMercure.amount);
        setBalanceSync(dataMercure.amount);
      } else {
        setBalanceSync(dataMercure.amount);
      }
    });

    return () => {
      es.close();
    };
  }, [paymentMethod, setBalance, isNavigation, setBalanceSync]);

  const isNeedUpdateBalance = (autoModeIsStart && (animationDisabled || autoModeAcceleration > 0));

  useEffect(() => {
    if ((isEnd || isNeedUpdateBalance) && !isNaN(Number(balanceSync))) {
      setBalance(balanceSync);
    }
  }, [isEnd, balanceSync, setBalance, isNeedUpdateBalance]);

  useEffect(() => {
    if ((!animationDisabled && autoModeIsStart && autoModeAcceleration === 0) || (!autoModeIsStart && !animationDisabled)) {
      return;
    }
    if ((responseData.id !== lastRespIdRef.current && (isEnd === true || isNeedUpdateBalance || animationDisabled))) {
      lastRespIdRef.current = responseData.id;
      if (isRoulette) {
        if (parseFloat(responseData.win) === 0) {
          setProfit({
            win: null,
            lose: responseData.lose
          });
        } else {
          setProfit({
            win: responseData.win,
            lose: null
          });
        }
      } else if (parseFloat(responseData.win) === 0) {
        setProfit({
          win: null,
          lose: responseData.lose
        });
      } else {
        setProfit({
          win: responseData.win,
          lose: null
        });
      }
    }
  }, [
    responseData.id,
    isEnd,
    autoModeIsStart,
    autoModeAcceleration,
    animationDisabled,
    lastRespIdRef,
    isNeedUpdateBalance,
  ]);

  // console.log('balance:', balance);
  // console.log('balanceSync:', balanceSync);
  const dataBalanceInfo = useMemo(() => ({ balance, profit }), [balance, profit]);
  const balanceInfo = useAnimationSyncData(dataBalanceInfo);
  // console.log('balanceInfo.balance:', balanceInfo.balance);
  return (
    isNavigation ? renderItemMethod :
      <StyledPaymentMethodWrapper>
        <StyledPaymentMethodList ref={paymentMethodListRef}>
          {renderItemMethod}
        </StyledPaymentMethodList>
        <StyledBalanceWrapper>
          <Balance
            ref={balanceInputRef}
            balance={Number(balanceInfo.balance === 0) && Number(balance) > 0 ? balance : balanceInfo.balance}
            bet={balanceInfo.profit}
            label={`${paymentMethod?.currency?.name || ''} ${t("balance")}`}
          />
          <InputGroup
            label={`${paymentMethod?.currency?.name || ''} ${t('depositAddress').toLowerCase()}`}
            type="text"
            name="wallet"
            className="form-text wallet"
            placeholder={t("placeholderWallet")}
            value={authenticated ? sT("createAddress") : lT('pleaseLogin')}
            readOnly
            onClick={(e) => e.target.select()}
          />
            <div className="balance-actions">
              <Dialog
                visible={paymentDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={() => setPaymentDialogVisible(false)}
                animation="zoom"
                footer={<StyledButton
                  onClick={() => setPaymentDialogVisible(false)}
                  color="neutral"
                  type="submit"
                  width="100"
                >
                  {sT("close")}
                </StyledButton>}
                maskAnimation="fade"
                title={t("titleFillIn")}
                forceRender={false}
                className="default-modal"
                style={{margin: "auto"}}
              >
                <PaymentInvoice
                  paymentMethod={paymentMethod}
                  selectPaymentMethod={selectPaymentMethod}
                  getPaymentMethod={getPaymentMethod}
                  setVisible={setPaymentDialogVisible}
                  visible={paymentDialogVisible}
                />
              </Dialog>
              <StyledButton
                color="success"
                onClick={handleFillIn}
                disabled={!paymentMethod}
              >
                {t("fillIn")}
              </StyledButton>
              <Dialog
                visible={payoutDialogVisible}
                wrapClassName="default-modal-wrapper"
                onClose={() => setPayoutDialogVisible(false)}
                animation="zoom"
                maskAnimation="fade"
                title={t("titleBringOut")}
                forceRender={false}
                className="default-modal"
                destroyOnClose={true}
                style={{margin: "auto"}}
              >
                <PayoutInvoice
                  balance={balance}
                  paymentMethod={paymentMethod}
                  setVisible={setPayoutDialogVisible}
                />
              </Dialog>
              <StyledButton
                color="danger"
                onClick={handleBringOut}
                disabled={!paymentMethod}
              >
                {t("bringOut")}
              </StyledButton>
            </div>
        </StyledBalanceWrapper>
      </StyledPaymentMethodWrapper>
  );
}));

export default RoulettePaymentMethodContainer;
