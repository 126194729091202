import React, {useContext, useEffect, useMemo, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { attributeNames, responseStatus } from "../../../utils/consts";
import { closableNotification } from "../notification/ClosableNotification";
import InputGroup from "../inputGroup/InputGroup";
import BetAction from "../betAction/BetAction";
import { StyledButton } from "../../styles/styledButton";
import AlertMessage from "../alert/Alert";
import http from "../../../http";
import { StyledSelect, StyledSelectLabel } from "../../styles/styledSelect";
import Select, { Option } from "rc-select";
import ReCaptcha from "../reCaptcha/ReCaptcha";
import { isNumeric } from "rc-drawer/lib/utils";
import { StyledModalLoader } from "../payment/styledPaymentInvoice";
import loadingGif from "../../../assets/images/loading.gif";
import Maintenance from "../maintenance/Maintenance";
import TransactionAttributesTagBlock from "../../payoutInvoice/TransactionAttributesTagBlock";
import { validationTag } from "../../payoutInvoice/validations/validations";
import {AppContext} from "../../../App";

const PayoutInvoice = ({ balance, paymentMethod, setVisible }) => {

  const [submitData, setSubmitData] = useState({
    amount: 0,
    paymentMethod: paymentMethod,
    attributes: []
  });

  const [transactionAttributes, setTransactionAttributes] = useState(null);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(paymentMethod);
  const [valueNumber, setValueNumber] = useState();
  const { t } = useTranslation("siteOptions");
  const recaptchaRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [displayBalanceError, setDisplayBalanceError] = useState(false);
  const [isValueChanged, setIsvalueChanged] = useState(false);
  const [isMemo, setIsMemo] = useState(false);
  const [codeActive, setCodeActive] = useState("");
  const { twoFaIsActive } = useContext(AppContext);
  const [paymentData, setPaymentData] = useState({
    loading: false,
    invoice: null,
    methods: [],
    maintenance: false,
  });

  const createPayoutInvoice = (event) => {
    event.preventDefault();

    const data = submitData;

    data.paymentMethod = "/api/payment-methods/" + selectedPaymentMethod.id;
    data.amount = valueNumber?.toString();

    const amount = parseFloat(data.amount);

    if (!amount) {
      closableNotification(t("enterSum"), "error");
      return;
    }

    if (transactionAttributes.tag && !isMemo) {
      const element = data.attributes.find(item => item.id === transactionAttributes["tag"].id);
      const isValidationTagError = validationTag(element?.value, t);

      if (isValidationTagError) {
        return;
      }
    }

    const validationResult = validate(amount, true);

    if (validationResult) {
      setDisplayBalanceError(true);
      closableNotification(validationResult, "error");
      return;
    }

    const captchaToken = recaptchaRef.current.getValue();
    recaptchaRef.current.reset();

    data.captchaToken = captchaToken;

    axios.post("/api/payouts", data, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        closableNotification(t("withdrawalSuccessfully"), "success");
        setSubmitData({
          amount: 0,
          paymentMethod: selectedPaymentMethod,
          attributes: [],
          code: codeActive ?? null,
        });
        setVisible(false);
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_ERROR_VALIDATION) {
        closableNotification(error.response.data.error, "error");
      }
    });
  };

  useEffect(() => {
    if (isMemo) {
      setSubmitData(prev => {
        return {
          ...prev,
          attributes: prev.attributes.filter(item => item.name !== attributeNames.TAG)
        };
      });
    }
  }, [isMemo]);

  const fetchTransactionAttributes = () => {
    setLoading(true);
    axios.get("/api/transaction-attributes?paymentMethod.id=" + selectedPaymentMethod.id + "&transactionType=payout",
      userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        const attributes = response.data["hydra:member"];
        if (Array.isArray(attributes) && attributes.length > 0) {
          const object = {};
          attributes.forEach(item => object[item.name] = item);
          setTransactionAttributes(object);
        } else {
          setTransactionAttributes(null);
        }
      }
    }).catch(error => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    }).finally(() => {
      setLoading(false);
    });
  };

  const getPaymentMethodsSystem = () => {
    setLoading(true);
    http.get(`/api/payment-methods?currency.asset=${selectedPaymentMethod.currency.asset}`, userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK) {
        setPaymentMethods(response.data["hydra:member"]);
      }
    }).catch(error => {
      closableNotification(error.response.data.error, "error");
    }).finally(() => {
      setLoading(false);
    });
  };

  const handleInput = (valueNumber) => {
    const filteredValue = valueNumber.replace(/[^0-9.]/g, '');

    const value = filteredValue.replace(/,/g, '.');

    if (!isNaN(value) && (value.match(/\./g) || []).length <= 1) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart && decimalPart.length > 8) {
        decimalPart = decimalPart.substring(0, 8);
      }
      setValueNumber(decimalPart !== undefined ? [intPart, decimalPart].join('.') : intPart);
    } else {
      setValueNumber(value);
    }

    setIsvalueChanged(true);
  };

  const min = useMemo(() => {
    return parseFloat((selectedPaymentMethod?.payoutFee?.min + selectedPaymentMethod?.payoutFee?.constant).toFixed(8));
  }, [selectedPaymentMethod]);

  const max = useMemo(() => {
    return parseFloat(selectedPaymentMethod?.payoutFee?.max.toFixed(8));
  }, [selectedPaymentMethod]);

  useEffect(() => {
    setSelectedPaymentMethod(paymentMethod);
  }, [paymentMethod]);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchTransactionAttributes();
    getPaymentMethodsSystem();
    return () => {
      setDisplayBalanceError(false);
      setIsvalueChanged(false);
      setValueNumber(undefined);
    };
  }, []);

  const handleChangePaymentSystem = (value) => {
    setSelectedPaymentMethod(paymentMethods.find((paymentMethod) => paymentMethod.id === value));
  };

  if (loading) {
    return <StyledModalLoader>
      <img src={loadingGif} alt={"loading..."}/>
    </StyledModalLoader>;
  }

  const validate = (value, forceValidateBalance = false) => {
    if (!isValueChanged) {
      return false;
    }
    if (!isNumeric(value)) {
      return true;
    }
    value = parseFloat(value);

    if ((displayBalanceError || forceValidateBalance) && selectedPaymentMethod?.balances?.amount) {
      if (value > selectedPaymentMethod?.balances?.amount) {
        return t("insufficientFunds") + `: ${selectedPaymentMethod?.balances?.amount} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    if (selectedPaymentMethod?.payoutFee?.min) {
      if (value < min) {
        return t("minimumAmount") + `: ${min} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    if (selectedPaymentMethod?.payoutFee?.max) {
      if (value > max) {
        return t("maximumAmount") + `: ${max} ${selectedPaymentMethod.currency.asset}`;
      }
    }
    return false;
  };

  const updateAttribute = (attribute, value) => {
    if (attribute.id) {
      const name = `/api/transaction-attributes/${attribute.id}`;

      setSubmitData(old => {
        const attributes = old.attributes;

        const index = attributes.findIndex(attribute => attribute.attribute === name);

        if (index >= 0) {
          attributes[index].value = value;
        } else {
          attributes.push({ attribute: name, name: attribute.name, value, id: attribute.id });
        }

        old.attributes = attributes;

        return { ...old };
      });
    }
  };

  return (
    <div id="payout-invoice">
      {transactionAttributes && transactionAttributes.wallet && selectedPaymentMethod.payoutFee.active ? <>
          <InputGroup
            autocomplete="off"
            id="wallet"
            type="text"
            label={t("enterYour") + " " + selectedPaymentMethod.currency.name + " " +
                selectedPaymentMethod.name_view + " " + t("address")}
            name="wallet"
            placeholder={selectedPaymentMethod.currency.name + " " + selectedPaymentMethod.name_view + " " + t("address")}
            onChange={event => {
              updateAttribute(
                transactionAttributes.wallet,
                event.target.value
              );
            }}
          />
          {transactionAttributes.tag &&
            <TransactionAttributesTagBlock
              updateAttribute={updateAttribute}
              transactionAttributes={transactionAttributes}
              isMemo={isMemo}
              setIsMemo={setIsMemo}
              attributes={submitData.attributes}
              t={t}
            />
          }
          <InputGroup
            autocomplete="off"
            id="amount"
            type="text"
            label={t("enterSum")}
            name="amount"
            placeholder={t("amount")}
            autoInput
            onChange={event => {
              setSubmitData((prevState) => ({
                ...prevState,
                amount: event.target.value
              }));
            }}
            value={valueNumber}
            betAction={
              <BetAction
                value={valueNumber}
                setValue={handleInput}
                min={Math.min(parseFloat(min), selectedPaymentMethod?.balances?.amount) === 0 ? "0.00000000" : Math.min(parseFloat(min), selectedPaymentMethod?.balances?.amount)}
                max={Math.min(parseFloat(max), selectedPaymentMethod?.balances?.amount)}
              />}
            error={validate(valueNumber)}
            hints={{
              ...selectedPaymentMethod,
              min: min,
              max: max,
            }}
            handleInput={handleInput}
          />
        </> :
        <InputGroup
          autocomplete="off"
          id="wallet"
          type="text"
          label={t("enterYour") + " " + selectedPaymentMethod.currency.name + " (" +
            selectedPaymentMethod.paymentSystem.name + ") " + t("address")}
          name="wallet"
          style={{ textAlign: "center" }}
          placeholder={t("Wallet Maintenance")}
          value={t("Wallet Maintenance")}
          onInput={e => e.target.value = t("Wallet Maintenance")}
        />
      }
      {!!paymentMethods ? (
          <>
            <StyledSelect mb={10} style={{marginTop: "17px", position: "relative"}} id={"select-payout"}>
              <StyledSelectLabel style={{ fontSize: 16 }}>{t("chooseNetwork")}:</StyledSelectLabel>
              <Select
                getPopupContainer={() => document.getElementById("select-payout")}
                className="custom-select"
                name="category"
                value={selectedPaymentMethod?.id}
                onChange={(value) => handleChangePaymentSystem(value)}
                virtual={false}
                dropdownAlign={{offset: [-1, 12]}}
              >
                {paymentMethods.map((value, key) => (
                  <Option
                    value={value.id}
                    key={key}
                  >
                    {`${Number(value?.payoutFee?.constant).toFixed(8)} ${selectedPaymentMethod.currency.asset} - ${value?.name_view ? value?.name_view : "ERROR"}`}
                  </Option>
                ))}
              </Select>
            </StyledSelect>
          </>) :
        null
      }
      {twoFaIsActive && selectedPaymentMethod.payoutFee.active ? <InputGroup
          id="twoFaCode"
          label={t("inputKey")}
          name="twoFaCode"
          value={codeActive}
          handleChange={(event) => setCodeActive(event.target.value)}
          type="text"
          maxLength="6"
          placeholder={t("inputKeyP")}
          autocomplete="off"
      /> : null}
      {transactionAttributes && transactionAttributes.wallet && selectedPaymentMethod.payoutFee.active ? <>
            <ReCaptcha recaptchaRef={recaptchaRef}/>
          <AlertMessage
            type="warning"
            message={t("makeSureYouEnteredCorrect") + " " +
              selectedPaymentMethod.currency.name + " " + t("address") + ". " +
              t("theTransactionFeeWillBe") + " " + selectedPaymentMethod.currency.name + "."}
            mt="30"
            mb="30"
          />
          <div className="confirm-action">
            <StyledButton
              onClick={createPayoutInvoice}
              color="neutral"
              type="submit"
              width="100"
            >{t("confirmWithdrawal")}</StyledButton>
          </div>
        </> :
        <>
          <Maintenance
            message={t("technicalMaintenance")}
            description={t("paymentMaintenance", {
              wallet: selectedPaymentMethod.currency.asset + (selectedPaymentMethod.name_view ? " (" + selectedPaymentMethod.name_view + ")" : "")
            })}
          />
          <StyledButton
            onClick={() => setVisible(false)}
            color="neutral"
            type="submit"
            width="100"
            mt={30}
          >
            {t("close")}
          </StyledButton>
        </>
      }
    </div>
  );
};

export default PayoutInvoice;