import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useBetween } from "use-between";
import BurgerStates from "./BurgerStates";
import BalanceStates from "../../games/BalanceStates";
import chat from "../../../assets/images/leftSidebar/menu-chat-icon.svg";
import games from "../../../assets/images/leftSidebar/menu-games-icon.svg";
import cons from "../../../assets/images/leftSidebar/menu-fair-icon.svg";
import token from "../../../assets/images/leftSidebar/menu-token-icon.svg";
import stats from "../../../assets/images/leftSidebar/menu-stats-icon.svg";
import bonus from "../../../assets/images/leftSidebar/menu-bonus-icon.svg";
import {
  StyledAnimatedProfit,
  StyledBalance, StyledBonus,
  StyledBurger,
  StyledChat,
  StyledEnter,
  StyledFair,
  StyledGames,
  StyledMobileNavWrapper,
  StyledMoney,
  StyledNavAvatar,
  StyledStats,
  StyledToken
} from "./styledMobileNavigation";
import { AppContext } from "../../../App";
import { LeftSidebarMenu, responseStatus, showModal } from "../../../utils/consts";
import LoginContainer from "../../login/LoginContainer";
import RegistrationContainer from "../../registration/RegistrationContainer";
import { useAnimationSyncData } from '../../games/AnimationSyncDataStates';
import Dialog from "rc-dialog";
import { useTranslation } from "react-i18next";
import { StyledFlexAlign } from "../inputGroup/styledInputGroup";
import { VisibleLeftSidebarStates } from "../leftSidebar/VisibleLeftSidebarStates";
import http from "../../../http";
import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import defaultImg from "../../../assets/images/no-avatar.svg";
import { closableNotification } from "../notification/ClosableNotification";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";

const MobileNavigation = (props) => {
  const [visible, setVisible] = useState("");

  const { authenticated } = useContext(AppContext);

  const {
    isOpenBurger,
    setIsOpenBurger,
    isOpenCurrency,
    setIsOpenCurrency,
    isOpenChat,
    setIsOpenChat,
    setIsOpenProfile,
    setIsOpenBalance,
    balanceRef,
  } = useBetween(BurgerStates);
  const {
    setVisible: setLeftSidebarVisible,
    setTokenBonusVisible,
    setGameBonusVisible,
    menu,
    setMenu,
  } = useBetween(VisibleLeftSidebarStates);

  const showMenu = useCallback((type) => {
    setLeftSidebarVisible(true);
    setMenu(type);

    if (menu === type) {
      setLeftSidebarVisible(false);
      setMenu("");
    }
  }, [menu, setLeftSidebarVisible, setMenu]);

  const { balance, activeCurrency, profit } = useBetween(BalanceStates);

  const { t } = useTranslation('header');

  const setOpenBurger = () => setIsOpenBurger(!isOpenBurger);

  const setOpenCurrency = () => setIsOpenCurrency(!isOpenCurrency);

  const setOpenChat = () => setIsOpenChat(!isOpenChat);

  const dataBalanceInfo = useMemo(() => ({ balance, profit }), [balance, profit]);
  const balanceInfo = useAnimationSyncData(dataBalanceInfo);

  const [avatar, setAvatar] = useState({ loaded: false, base64: null });

  const [changesQueue, setChangesQueue] = useState([]);

  const sliceBalance = (valueNumber) => {
    const value = `${valueNumber}`.replaceAll(',', '.');
    if (value && parseFloat(value) && value.includes('.')) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart.length > 8) {
        decimalPart = decimalPart.substring(0, 8);
      }
      if (intPart === '') {
        intPart = 0;
      }
      return [intPart, decimalPart].join('.');
    } else {
      return value;
    }
  }

  useEffect(() => {
    if (balanceInfo.profit.win || balanceInfo.profit.lose) {
      const id = Math.random().toString(16).slice(2);
      setChangesQueue(old => [
        ...old,
        {
          id: id,
          profit: balanceInfo.profit,
        }
      ]);
      setTimeout(() => {
        setChangesQueue(old => old.filter(item => item.id !== id))
      }, 1500)
    }
  }, [balanceInfo.profit]);

  useEffect(() => {
    const abortController = new AbortController();
    if (authenticated) {
      http.get("/api/media-objects", { ...userAuthenticationConfig(), signal: abortController.signal })
        .then(response => {
          if (response.status === responseStatus.HTTP_OK && response.data.base64 !== "") {
            setAvatar({ loaded: true, base64: response.data.base64 ?? defaultImg });
          } else {
            setAvatar({ loaded: true, base64: defaultImg });
          }
        })
        .catch(error => {
          setAvatar({ loaded: true, base64: defaultImg });
          if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
            closableNotification(error.response.data.error, "error");
          }
        });
    }
    return () => {
      abortController.abort();
    }
  }, [authenticated]);

  const profits = useMemo(() => {
    return changesQueue.map((item, key) => (
      <StyledAnimatedProfit
        key={key}
        animationDuration={200}
        id={`notification_${item.id}`}
        win={item.profit.win}
      >
        {item.profit.win || item.profit.lose}
      </StyledAnimatedProfit>
    ));
  }, [changesQueue]);

  return (
    <StyledMobileNavWrapper>
      <Dialog
        visible={visible}
        wrapClassName="default-modal-wrapper"
        onClose={() => setVisible("")}
        animation="zoom"
        maskAnimation="fade"
        title={visible === showModal.LOGIN ? t('login') : t('createAccount')}
        forceRender={false}
        className="default-modal"
      >
        <div className="default-modal__content">
          {visible === showModal.LOGIN ?
            <LoginContainer visible={visible} setVisible={setVisible}/> :
            <RegistrationContainer setVisible={setVisible}/>
          }
        </div>

      </Dialog>

      <StyledFlexAlign>
        <StyledBurger onClick={setOpenBurger} isOpen={isOpenBurger}>
          <span className={isOpenBurger ? "active-burger" : ""}></span>
          <span className={isOpenBurger ? "active-burger" : ""}></span>
          <span className={isOpenBurger ? "active-burger" : ""}></span>
        </StyledBurger>
        <StyledGames onClick={() => showMenu(LeftSidebarMenu.GAMES)}>
          <img src={games} alt="games"/>
        </StyledGames>
        <StyledChat onClick={setOpenChat}>
          <img src={chat} alt="chat"/>
        </StyledChat>
        <StyledFair onClick={() => showMenu(LeftSidebarMenu.CONTROL)}>
          <img src={cons} alt="fair"/>
        </StyledFair>
        {
          authenticated &&
          <>
            <StyledStats onClick={() => showMenu(LeftSidebarMenu.STATISTICS)}>
              <img src={stats} alt="stats"/>
            </StyledStats>
            <StyledBonus onClick={() => {
              setGameBonusVisible(true);
            }}>
              <img src={bonus} alt="bonus"/>
            </StyledBonus>
            <StyledToken onClick={() => {
              setTokenBonusVisible(true);
            }}>
              <img src={token} alt="token"/>
            </StyledToken>
          </>
        }
      </StyledFlexAlign>
      <StyledFlexAlign>
        {
          authenticated ?
            <>
              <StyledMoney onClick={setOpenCurrency}>
                <StyledPaymentMethodsIcons className={`payment-method-${activeCurrency}`}/>
              </StyledMoney>
              <StyledBalance ref={balanceRef} onClick={setIsOpenBalance}>
                {Number(sliceBalance(balanceInfo.balance)).toFixed(8)}
                {profits}
              </StyledBalance>
              {/*<Balance ref={balanceRef} balance={balanceInfo.balance} bet={balanceInfo.profit}/>*/}
              <StyledNavAvatar loading={avatar.loaded ? undefined : 1} onClick={setIsOpenProfile}>
                {avatar.loaded ? <img src={avatar.base64} alt="user-image"/> : <div className={'user-image_shimmer'}/>}
                <div className="rating" title={t('accountRating')}>
                  ∞
                </div>
              </StyledNavAvatar>
            </> :
            <>
              <StyledEnter onClick={() => setVisible(showModal.LOGIN)}>
                {t('logIn')}
              </StyledEnter>
              <StyledEnter onClick={() => setVisible(showModal.REGISTRATION)}>
                {t('signUp')}
              </StyledEnter>
            </>
        }
      </StyledFlexAlign>
    </StyledMobileNavWrapper>
  );
};

export default MobileNavigation;
