import React from "react";
// import { useTranslation } from 'react-i18next'
import { NavLink } from "react-router-dom";
import { generatePath } from "../../../utils/getLanguage";
import { TimestampToCustomFormat } from "../../../utils/timestampToDate";
import { StyledHistoryBet, StyledHistoryDate, StyledHistoryLink } from "../../styles/styledHistory";
import { StyledPaymentMethodsIcons } from "../../styles/StyledPaymentMethodsIcons";
// import { StyledDiceHistoryProgress } from './styledDice'

const RouletteBetsListItem = React.memo((props) => {
  const { value } = props;
  // const { t } = useTranslation("games");
  return (
    <tr>
      <td data-title="ID">
        <StyledHistoryLink as={NavLink} to={`/bet/${value.id}`}>
          <div>
            {new Intl.NumberFormat("en-US").format(value.id)}
          </div>
        </StyledHistoryLink>
      </td>
      <td data-title="Время / Игрок">
        <StyledHistoryDate>
            {TimestampToCustomFormat(value.createdAt)}
        </StyledHistoryDate>
        <StyledHistoryLink as={NavLink} to={generatePath(`/account/${value.user.nickname}`)}>
          <div>
            {value.user.nickname}
          </div>
        </StyledHistoryLink>
      </td>
      <td data-title="Валюта" className="roulette-history-table__currency">
        <StyledPaymentMethodsIcons className={`payment-method-${value.paymentMethod.currency.asset}`}/>
      </td>
      <td data-title="Выпавший номер" className="roulette-history-table__number">
        {value.drawnNumber}
      </td>
      <td data-title="Ставка">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {value.gameObject.chanceToWin}%
                </span>
          {value.bet} {value.paymentMethod.currency.asset}
        </StyledHistoryBet>
      </td>
      <td data-title="Выигрыш">
        <StyledHistoryBet losing={parseFloat(value.lose) === 0}>
                <span>
                  {parseFloat(value.lose) === 0 ? ((parseFloat(value.bet) + parseFloat(value.win)) / parseFloat(value.bet)).toFixed(4) : 0}x
                </span>
          {parseFloat(value.lose) === 0 ? (parseFloat(value.bet) + parseFloat(value.win)).toFixed(8) : (0).toFixed(8)} {value.paymentMethod.currency.asset}
        </StyledHistoryBet>
      </td>
    </tr>
  );
});

export default RouletteBetsListItem;
