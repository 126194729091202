import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import InputGroup from "../../elements/inputGroup/InputGroup";
import { closableNotification } from "../../elements/notification/ClosableNotification";

import userAuthenticationConfig from "../../../utils/userAuthenticationConfig";
import { responseStatus } from "../../../utils/consts";

import { StyledButton } from "../../styles/styledButton";
import { StyledBallsBetOptions } from "./styledBalls";
import { useBetween } from "use-between";
// import calculateGameStatistics from "../../../utils/calculateGameStatistics";
import BetAction from "../../elements/betAction/BetAction";
import AutoBetsContainer from "../../elements/autoBets/AutoBetsContainer";
import { CurrentHonestyControl } from "../../elements/leftSidebar/HonestyControl/CurrentHonestyControl";
import { VisibleLeftSidebarStates } from "../../elements/leftSidebar/VisibleLeftSidebarStates";
import { useAutoBetsStatesBalls } from "../../elements/autoBets/AutoBetsStates";
import BalanceStates from "../BalanceStates";
import { GAMES } from "../Constants";
import { useAnimationSync } from '../AnimationSyncDataStates';
import CurrentStatisticsStates from "../CurrentStatisticStates";


const BallsToPlayContainer = ({
  submitData,
  setSubmitData,
  responseData,
  setResponseData,
  errorData,
  setErrorData,
  handleChange,
  isRunMagnet,
  setIsRunMagnet,
  winAmount,
  isMobile,
  animationDisabled,
}) => {
  const { t } = useTranslation("games");

  const { autoModeIsStart, gameInfoRef, isDisableAutoBet } = useAutoBetsStatesBalls();
  const { currentHonestyControl } = useBetween(CurrentHonestyControl);
  const { visible } = useBetween(VisibleLeftSidebarStates);
  const { balance, setBalance } = useBetween(BalanceStates);
  const {
    updateStatistics
  } = useBetween(CurrentStatisticsStates);
  const { startAnimationSync, stopAnimationSync } = useAnimationSync();

  const suggestedNumbers = JSON.parse(submitData?.suggestedNumbers);

  const { clientSeed, serverSeedHash } = currentHonestyControl || {};

  const toPlay = useCallback((manualMode = true) => {
    setResponseData((prevState) => ({ ...prevState, drawnNumber: undefined, error: false }));
    setIsRunMagnet(true);

    let data = {
      bet: submitData.bet.toString(),
      clientSeed,
      isActiveStatistic: visible,
      paymentMethod: submitData.paymentMethod.id,
      serverSeedHash,
      suggestedNumbers: submitData.suggestedNumbers.toString(),
    };
    startAnimationSync();

    axios.post("/api/balls", data, userAuthenticationConfig(false)).then(response => {
      if (response.status === responseStatus.HTTP_CREATED) {
        // console.log('calculateGameStatistics', response.status);
        // setStatistic(calculateGameStatistics(response.data, 'balls'));
        updateStatistics(response.data, 'balls');
        response.data["manualMode"] = manualMode;
        if (!gameInfoRef.current.isLeavePage) {
          console.log(
            'Баланс після ставки:',
            response.data.balance,
            (parseFloat(response.data.lose) !== 0 ?
                `(-${response.data.lose})` :
                `(+${response.data.win})`
            ),
            // `(${response.headers['request-duration']}ms)`
          );
          setBalance(response.data.balance)
          setResponseData({
            ...response.data,
            betInfo: {...JSON.parse(response.data.betInfo), gameObject: {
                max: response.data.max,
                sign: response.data.sign,
                coefficient: response.data.coefficient,
                chanceToWin: response.data.chanceToWin
              }, drawnNumber: response.data.drawnNumber, suggestedNumbers: response.data.suggestedNumbers, resultNumber: response.data.resultNumber},
            bet: submitData.bet.toString(),
            suggestedNumbers: submitData.suggestedNumbers.toString(),
            sign: submitData.sign,
          });
        } else if (gameInfoRef.current) {
          gameInfoRef.current.responseData = response.data;
        }
      }
    }).catch(error => {
      stopAnimationSync();
      let terminate = false;
      console.log('error:', error);
      if (error?.response?.status) {
        terminate = true;
      }
      if (error.response?.status === responseStatus.HTTP_BAD_REQUEST || error.response?.status === responseStatus.HTTP_ERROR_VALIDATION) {
        closableNotification(error.response.data.error, "error");
      }
      if (error?.response?.status === responseStatus.HTTP_UNAUTHORIZED) {
        closableNotification(error?.response?.data?.detail, 'error');
      }
      if (!gameInfoRef.current.isLeavePage) {
        setResponseData((prevState) => ({ ...prevState, error: true }));
        setErrorData({ error: true, terminate });
      } else if (gameInfoRef.current) {
        gameInfoRef.current.responseData = { ...gameInfoRef.current.responseData, error: true };
        gameInfoRef.current.errorData = { ...gameInfoRef.current.errorData, terminate };
      }
    });
  }, [
    updateStatistics,
    clientSeed,
    serverSeedHash,
    setIsRunMagnet,
    setResponseData,
    submitData,
    visible,
    setErrorData,
    gameInfoRef,
    startAnimationSync,
    stopAnimationSync
  ]);

  const handlePlayClick = useCallback(() => {
    const getError = () => {
      if (!balance) {
        return t("topUpYourBalance");
      } else if (!suggestedNumbers?.length) {
        return t("placeYourBet");
      }

      return null;
    };

    const errorMessage = getError();

    if (errorMessage) {
      closableNotification(errorMessage, "error");
    } else {
      toPlay();
    }
  }, [balance, suggestedNumbers?.length, t, toPlay]);

  const sliceDecimal = (valueNumber) => {
    const value = `${valueNumber}`.replaceAll(',', '.');
    if (value && parseFloat(value) && value.includes('.')) {
      let [intPart, decimalPart] = value.split('.');
      if (decimalPart.length > 3) {
        decimalPart = decimalPart.substring(0, 3);
      }
      if (intPart === '') {
        intPart = 0;
      }
      return [intPart, decimalPart].join('.');
    } else {
      return value;
    }
  }

  const formattedCoefficient = () => {
    return `${submitData.suggestedNumbers === "" ? "0" : (responseData.coefficient ? sliceDecimal(responseData.coefficient) : "0")}x`
  }

  return (
    <StyledBallsBetOptions>
      <div className="wrapper-group">
        <InputGroup
          label={t("bet")}
          type="text"
          name="bet"
          value={submitData?.bet}
          onChange={handleChange}
          position="center"
          betAction={
            <BetAction
              isPlay={isRunMagnet}
              value={submitData.bet}
              handleChange={handleChange}
              setValue={(data) => setSubmitData((prevState) => ({ ...prevState, bet: data }))}
              max={Number(balance)}
              min="0.00000001"
            />}
          readOnly={isRunMagnet || autoModeIsStart}
          // error={
          //   submitData.bet < 0.00000001 || submitData.bet > submitData.paymentMethod?.balances?.amount}
        />
        <InputGroup
          label={t("chanceToWin")}
          type="text"
          value={`${responseData.chanceToWin ?? "0"}%`}
          name="chanceToWin"
          readOnly
        />
        <InputGroup
          label={t("win")}
          type="text"
          value={!isNaN(Number(winAmount)) ? Number(winAmount).toFixed(8) : Number(0).toFixed(8)}
          name="win"
          readOnly
        />
        <InputGroup
          label={t("coefficient")}
          type="text"
          value={formattedCoefficient()}
          name="coefficient"
          readOnly
        />
      </div>
      <div className="wrapper-group">
        <StyledButton color={(isRunMagnet || autoModeIsStart || isDisableAutoBet) ? "danger" : "main"}
                      onClick={handlePlayClick} disabled={isRunMagnet || autoModeIsStart || isDisableAutoBet}>
          {t("startMagnet")}
        </StyledButton>
        <AutoBetsContainer
          game={GAMES.BALLS}
          toPlay={toPlay}
          isPlay={isRunMagnet}
          responseData={responseData}
          submitData={submitData}
          setSubmitData={setSubmitData}
          setStopAnimation={setIsRunMagnet}
          errorData={errorData}
          setErrorData={setErrorData}
          isMobile={isMobile}
          animationDisabled={animationDisabled}
        />
      </div>
    </StyledBallsBetOptions>
  );
};

export default BallsToPlayContainer;
