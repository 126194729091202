import styled from "styled-components";
import Dialog from "rc-dialog";
import bonusModalBg from "../../../../assets/images/backgrounds/popup-bonus.svg";

export const StyledBonusDialog = styled(Dialog)`
    &.default-modal-bonus {
        margin: auto;
        
        &::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 6px;
            width: 100%;
            height: 100%;
            z-index: 1;
            background: url(${bonusModalBg}) no-repeat center top;
        }

        &::after {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            right: 6px;
            width: 100%;
            height: 100%;
            z-index: 1;
        }

        & .rc-dialog-content {
            overflow: hidden;

            .rc-dialog-body {
                background: none;
            }

            .rc-dialog-header {
                border-bottom: none;
                z-index: 2;
                background: none;
                box-shadow: none;
                
                .rc-dialog-title {
                    color: ${({theme}) => theme.bonusGame.titleForm};
                }
            }
            
            .rc-dialog-close {
                border: 1px solid${({theme}) => theme.bonusGame.titleForm};
                color: ${({theme}) => theme.bonusGame.titleForm};
            }
        }
    }
    .custom-hint {
        position: relative;
        width: 0;
        transform: translate(20px, -70px);
        z-index: 100;
    }
`;

export const StyledBonusWrapper = styled.div`
        position: relative;
    .blocked-info-block {
        display: flex;
        justify-content: space-between;
        align-items: center;
        z-index: 2;
        position: relative;
        font-size: 18px;
        padding: 7px 5px;
        span {
            color: ${({theme}) => theme.bonusGame.textSec};
        }
    }
    .claim-info-block {
        h3 {
            text-transform: uppercase;
        }
        span {
            color: ${({theme}) => theme.bonusGame.textSec};
        }
        p {
            padding: 15px 0;
            color: ${({theme}) => theme.bonusGame.textSec};
            a, .link {
                color: ${({theme}) => theme.bonusGame.link};
                cursor: pointer;
            }
            a:hover, .link:hover {
                text-decoration: underline;
                text-underline-offset: 3px;
            }
        }
        .info-bonus {
            padding-top: 0;
        }
    }
    .bonus-table-header {
        color: ${({theme}) => theme.bonusGame.textMain};
    }
    .bonus-row-table, .bonus-row-table * {
        background-color: ${({theme}) => theme.bonusGame.tableItem};
        color: ${({theme}) => theme.bonusGame.textSec};
        font-size: 14px;
    }
    .bonus-row-table a {
        border-bottom: ${({theme}) => theme.bonusGame.textSec} dashed 1px;
        transition: all 0.3s ease !important;
    }
    .bonus-row-table a .nickname {
        transition: all 0.3s ease !important;
    }
    .bonus-row-table a:hover {
        border-bottom: ${({theme}) => theme.accountSettingLink.color} dashed 1px;
    }
    .bonus-row-table  a:hover .nickname {
        color: ${({theme}) => theme.accountSettingLink.color};
    }
    .bonus-modal-block {
        background: ${({theme}) => theme.bonusGame.blockBg};
    }
`;

export const StyledTitleBlock = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0 25px;
  z-index: 2;
  position: relative; 
  font-size: 16px;

  span {
    color: ${({theme}) => theme.bonusGame.textSec};
    font-weight: 500;
    display: block;
    padding-top: 5px;
    text-transform: uppercase;
  }

  b {
    text-shadow: 0 3px 2.9px rgb(32 32 32 / 70%);
    font-size: 36px;
    font-weight: 500;
    color: #4287f5;
  }
`;

export const StyledButtonBonus = styled.button`
    background: #ffba00;
    color: #ffffff;
    width: 200px;
    height: 45px;
    z-index: 2;
    position: relative;
    border: 0;
    outline: 0;
    box-shadow: none;
    text-transform: uppercase;
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 20px;
    margin-bottom: 5px;
    
    &:hover {
        background: #ffa800;
    }

    .spinner-box {
        text-align: left;
        height: 20px;
        margin-top: -30px;
    }

    &.disabled {
        cursor: not-allowed;
    }
    
    &.disabled::after {
        cursor: not-allowed;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.5;
        border-radius: 5px;
        pointer-events: none;
    }
    
    &.disabled:hover {
        background: #ffba00;
    }
`;

export const StyledMinimumLimit = styled.b`
    z-index: 2;
    position: relative;
    color: ${({theme}) => theme.bonusGame.textSec};
    font-weight: 500;
    display: block;
    padding-top: 5px;
    text-transform: uppercase;
    text-align: center;
    font-size: 16px;
    margin-bottom: 15px;
`

export const StyledViewMore = styled.div`
    font-size: 14px;
    text-align: center;
    text-transform: uppercase;
    display: table;
    text-decoration: underline;
    text-underline-offset: 4px;
    z-index: 2;
    position: relative;
    margin-right: auto;
    margin-left: auto;
`