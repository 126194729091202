import React, { useCallback, useContext, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { closableNotification } from "../notification/ClosableNotification";
import LeftSidebarGamesList from "./Games/LeftSidebarGamesList";
import LeftSidebarChat from "./Chat/LeftSidebarChat";

import games from "../../../assets/images/leftSidebar/menu-games-icon.svg";
import chat from "../../../assets/images/leftSidebar/menu-chat-icon.svg";
import cons from "../../../assets/images/leftSidebar/menu-fair-icon.svg";
import stats from "../../../assets/images/leftSidebar/menu-stats-icon.svg";
import tokenBonus from "../../../assets/images/leftSidebar/menu-token-icon.svg";
import bonus from "../../../assets/images/leftSidebar/menu-bonus-icon.svg";

import { LeftSidebarMenu } from "../../../utils/consts";

import {
  StyledBonusButton,
  StyledChatButton,
  StyledControlButton,
  StyledGamesButton,
  StyledLeftSidebarActions,
  StyledLeftSidebarContainer,
  StyledLeftSidebarWrapper,
  StyledStatsButton,
  StyledTokenButton
} from "./styledLeftSidebar";
import LeftSidebarStatisticsContainer from "./Statistics/LeftSidebarStatisticsContainer";
import { useBetween } from "use-between";
import LeftSidebarHonestyControlContainer from "./HonestyControl/LeftSidebarHonestyControlContainer";
import LeftSideBarTokenBonusContainer from "./TokenBonus/LeftSideBarTokenBonusContainer";
import { VisibleLeftSidebarStates } from "./VisibleLeftSidebarStates";
import { AppContext } from "../../../App";
import BurgerStates from "../mobileNavigation/BurgerStates";
import BonusModal from "./Bonus/BonusModal";
import BonusRulesModal from "./BonusRules/BonusRulesModal";

const getClassName = (isActive) => {
  return isActive ? { className: "active" } : undefined;
};

const LeftSidebar = React.memo(({ isNavigation = false }) => {
  const {
    isOpenBurger,
    setIsOpenBurger,
  } = useBetween(BurgerStates);

  const {
    visible,
    setVisible,
    menu,
    setMenu,
    message,
    tokenBonusVisible,
    setTokenBonusVisible,
    gameBonusVisible,
    setGameBonusVisible,
    rulesBonusVisible,
    setRulesBonusVisible
  } = useBetween(VisibleLeftSidebarStates);

  const { t } = useTranslation("leftSidebar");
  const { authenticated, user } = useContext(AppContext);

  const showMenu = useCallback((type) => {
    setVisible(true);
    setMenu(type);

    setIsOpenBurger(false)

    if (menu === type) {
      setVisible(false);
      setMenu("");
    }

  }, [menu, setMenu, setVisible]);

  const renderLeftSidebar = useMemo(() => {
    switch (menu) {
      case LeftSidebarMenu.GAMES:
        return (
          <LeftSidebarGamesList
            setVisible={setVisible}
            setMenu={setMenu}
          />
        );
      case LeftSidebarMenu.CHAT:
        return (
          <LeftSidebarChat
            setVisible={setVisible}
            setMenu={setMenu}
            visible={visible}
            message={message}
          />
        );
      case LeftSidebarMenu.STATISTICS:
        return (
          <LeftSidebarStatisticsContainer
            setVisible={setVisible}
            setMenu={setMenu}
            user={user}
          />
        );
      case LeftSidebarMenu.CONTROL:
        return (
          <LeftSidebarHonestyControlContainer
            setVisible={setVisible}
            setMenu={setMenu}
            user={user}
          />
        );
      default:
        return null;
    }
  }, [menu, setVisible, setMenu, visible, message, user]);

  const handleChangeTokenBonusVisible = () => {
    if (!authenticated) {
      closableNotification("Full authentication is required to access this resource.", "error");
    } else {
      setTokenBonusVisible(true);
    }
  };

  useEffect(() => {
    const root = document.getElementById("root");

    if (window.innerWidth > 1100) {
      if (visible) {
        root.className = "landslideLeft";
      } else {
        root.className = "";
      }
    }
  }, [visible]);

  useEffect(() => {
    const onResize = () => {
      const root = document.getElementById("root");
      root.className = "";
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  return (
    <StyledLeftSidebarWrapper
      visible={visible}
      isNavigation={isNavigation}
    >
      <StyledLeftSidebarContainer id="leftSidebar">
        {renderLeftSidebar}
      </StyledLeftSidebarContainer>
      <StyledLeftSidebarActions
        isNavigation={isNavigation}
      >
        <ul>
          <StyledGamesButton
            {...getClassName(menu === LeftSidebarMenu.GAMES)}
            onClick={() => showMenu(LeftSidebarMenu.GAMES)}
            isNav={isNavigation}
          >
            <img
              src={games}
              alt="games"
              width={38}
            />
            <span>{t("games")}</span>
          </StyledGamesButton>
          {!isNavigation ?
            <StyledChatButton
              {...getClassName(menu === LeftSidebarMenu.CHAT)}
              onClick={() => showMenu(LeftSidebarMenu.CHAT)}
            >
              <img
                src={chat}
                alt="chat"
                width={30}
              />
              <span>{t("chat")}</span>
            </StyledChatButton> : ""}
          <StyledControlButton
            {...getClassName(menu === LeftSidebarMenu.CONTROL)}
            onClick={() => showMenu(LeftSidebarMenu.CONTROL)}
            isNav={isNavigation}
          >
            <img
              src={cons}
              alt="cons"
              width={30}
            />
            <span>{t("control")}</span>
          </StyledControlButton>
          {
            authenticated ? <StyledStatsButton
                {...getClassName(menu === LeftSidebarMenu.STATISTICS)}
                onClick={() => showMenu(LeftSidebarMenu.STATISTICS)}
                isNav={isNavigation}
              >
                <img
                  src={stats}
                  alt="stats"
                  width={30}
                />
                <span>{t("stats")}</span>
              </StyledStatsButton> :
              null
          }
          {
            authenticated ? <><StyledBonusButton
                    {...getClassName(menu === LeftSidebarMenu.BONUS_MODAL)}
                    onClick={() => setGameBonusVisible(true)}
                    isNav={isNavigation}
                >
                  <img
                      src={bonus}
                      alt="bonus"
                      width={30}
                  />
                  <span>{t("bonus")}</span>
                </StyledBonusButton>
            {!isNavigation ? <BonusModal
                    setVisible={setGameBonusVisible}
                    user={user}
                    visible={gameBonusVisible}
                    setVisibleRules={setRulesBonusVisible}
                  /> : null}
                </>
                : null
          }
          {
            authenticated ? <>
                <StyledTokenButton
                  {...getClassName(menu === LeftSidebarMenu.TOKEN_BONUS)}
                  onClick={handleChangeTokenBonusVisible}
                  isNav={isNavigation}
                >
                  <img
                    src={tokenBonus}
                    alt="tokenBonus"
                    width={29}
                  />
                  <span>{t("tokenBonus")}</span>
                </StyledTokenButton>
                {!isNavigation ?
                  <LeftSideBarTokenBonusContainer visible={tokenBonusVisible} setVisible={setTokenBonusVisible} t={t} /> : null}
              </>
              :
              null
          }
          {authenticated ? <BonusRulesModal visible={rulesBonusVisible} setVisible={setRulesBonusVisible} setBonusVisible={setGameBonusVisible}/> : null}
        </ul>
      </StyledLeftSidebarActions>
    </StyledLeftSidebarWrapper>
  );
});

export default LeftSidebar;
