import jwt_decode from "jwt-decode";
import { isExpired } from "./checkExpiredToken";
import eventBus from "./eventBus";

const getUserInfo = () => {
  let token = null;

 const data = localStorage.getItem('token');

  if (data !== "") {
    try {
      token = jwt_decode(data);
    } catch (e) {
      localStorage.removeItem("token");
      localStorage.removeItem("twoFaIsActive");
      return null;
    }

    if (token.exp && isExpired(token.exp * 1000)) {
      localStorage.removeItem("token");
      localStorage.removeItem("twoFaIsActive");
      return null;
    }

    return token;
  } else {
    localStorage.removeItem("token");
    localStorage.removeItem("twoFaIsActive");
  }

  return null;
};

export default getUserInfo;