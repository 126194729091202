import React, { useContext, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import Tabs, { TabPane } from "rc-tabs";
import BallsBetsHistoryContainer from "./BallsBetsHistoryContainer";

import { StyledBallsHistoryWrapper } from "./styledBalls";
import { StyledContentWrapper } from "../../styles/styledContainer";
import { AppContext } from "../../../App";
import SendTipWindow from "../../profileActionsWindow/SendTipWindow";
import ProfileActionsWindow from "../../profileActionsWindow/ProfileActionsWindow";
import ReactHintFactory from "react-hint";
import "react-hint/css/index.css";
import { useBetween } from "use-between";
import ProfileActionsStates from "../../profileActionsWindow/ProfileActionsStates";
import BallsStates from "./BallsStates";

const ReactHint = ReactHintFactory(React);

const BallsBetsHistoryWrapper = React.memo(() => {

  const { t } = useTranslation("games");

  const [defaultKeyForBets, setDefaultKeyForBets] = useState(localStorage.getItem("keyForHistoryTab") ?? "allBets");

  const { authenticated } = useContext(AppContext);
  const { responseData, setResponseData } = useBetween(BallsStates);

  const {
    handleProfileActionsVisible
  } = useBetween(ProfileActionsStates);

  const changeTab = useCallback((key) => {
    setResponseData((prevState) => ({...prevState, betInfo: null}));
    localStorage.setItem("keyForHistoryTab", key);
    setDefaultKeyForBets(key);
    handleProfileActionsVisible(null, 'balls')
  }, [handleProfileActionsVisible]);

  const [activeNickname, setActiveNickname] = useState(null);
  const [room, setRoom] = useState(null);

  const renderHint = useCallback((target) => {
    const { id, nickname, room } = target.dataset;
    setActiveNickname(nickname);
    setRoom(room);

    return <ProfileActionsWindow
      placement="balls"
      keys={+id}
      nickname={nickname}
    />;
  }, []);

  return (
    <StyledBallsHistoryWrapper>
      {
        authenticated &&
        <>
          <ReactHint
            persist
            attribute="data-custom"
            className="custom-hint"
            onRenderContent={renderHint}
            events={{ click: true }}
          />
          <SendTipWindow activeNickname={activeNickname} room={room}/>
        </>
      }
      <StyledContentWrapper>
        <Tabs
          defaultActiveKey={defaultKeyForBets}
          tabPosition="top"
          className="default-tabs default-tabs-top default-tabs-top_centered"
          onChange={changeTab}
          destroyInactiveTabPane={true}
        >
          {authenticated &&
            <TabPane tab={t("myBets")} key="myBets">
              <BallsBetsHistoryContainer
                type="My"
                responseData={responseData}
                setResponseData={setResponseData}
              />
            </TabPane>}
          <TabPane tab={t("allBets")} key="allBets">
            <BallsBetsHistoryContainer
              type="All"
              responseData={responseData}
              setResponseData={setResponseData}
            />
          </TabPane>
        </Tabs>
      </StyledContentWrapper>
    </StyledBallsHistoryWrapper>
  );
});

export default BallsBetsHistoryWrapper;
