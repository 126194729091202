import React, { useRef } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputGroup from "../elements/inputGroup/InputGroup";
import basicAuthenticationConfig from "../../utils/basicAuthenticationConfig";
import LoadButton from "../elements/spinner/ButtonSpinner";
import AlertMessage from "../elements/alert/Alert";

import { StyledAuthForm } from "./styledLogin";
import { StyledButton } from "../styles/styledButton";
import ReCaptcha from "../elements/reCaptcha/ReCaptcha";
import {showModal} from "../../utils/consts";

const LoginForm = ({ authData, setAuthData, loading, setVisible }) => {
  const { t } = useTranslation('header');
  const recaptchaRef = useRef(null);

  const handleSubmit = (event) => {
    event.preventDefault();

    const captchaToken = recaptchaRef?.current?.getValue();
    recaptchaRef.current.reset();

    let data = {
      email: event.target.email.value,
      password: event.target.password.value
    };

    setAuthData({
      ...authData,
      body: {
        captchaToken
      },
      headers: basicAuthenticationConfig(data.email, data.password)
    });
  };

  return (
    <StyledAuthForm>
      <form onSubmit={handleSubmit}>
        <InputGroup
          id="email"
          type="email"
          label={t('enterEmail')}
          name="email"
          required
        />
        <InputGroup
          id="password"
          type="password"
          label={t('enterPassword')}
          name="password"
          required
        />
        <AlertMessage
          mt="25"
          mb="25"
          message={<React.Fragment>
            {t('forgotPassword') + ' '}
            <NavLink
              to={""}
              className="default-link"
              onClick={(e) => {
                  e.preventDefault();
                  setVisible(showModal.FORGOT_PASSWORD)
                }
              }
            >
              {t('typeHere')}
            </NavLink>
          </React.Fragment>}
        />
        <ReCaptcha recaptchaRef={recaptchaRef}/>
        <div className="auth-action">
          {!loading ?
            <StyledButton
              color="neutral"
              type="submit"
            >
              {t('enter')}
            </StyledButton> :
            <LoadButton
              text={t('enter')}
              color="neutral"
            />}
        </div>

      </form>
    </StyledAuthForm>
  );
};

export default LoginForm;
