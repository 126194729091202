import { logDOM } from "@testing-library/react";
import { useTranslation } from "react-i18next";
import {
  StyledBonusDialog,
  StyledBonusWrapper,
  StyledButtonBonus,
  StyledMinimumLimit,
  StyledTitleBlock, StyledViewMore
} from "./BonusModalStyle";
import { Link, NavLink } from "react-router-dom";
import {
  StyledBody,
  StyledHead,
  StyledHeadTitle, StyledItem, StyledRow,
  StyledStaticTable,
  StyledTableSimple,
  StyledTextBlock
} from "../../siteOptions/styledSiteOptions";
import React, {useContext, useEffect, useRef, useState} from "react";
import { TimestampToDateTimeWithSecondsABC } from "../../../../utils/timestampToDate";
import { StyledHistoryLink } from "../../../styles/styledHistory";
import { generatePath } from "../../../../utils/getLanguage";
import { closableNotification } from "../../notification/ClosableNotification";
import userAuthenticationConfig from "../../../../utils/userAuthenticationConfig";
import { bonusType, depositBonuses, responseStatus } from "../../../../utils/consts";
import axios from "axios";
import Spinner from "../../spinner/Spinner";
import {AppContext} from "../../../../App";
import {useBetween} from "use-between";
import ProfileActionsStates from "../../../profileActionsWindow/ProfileActionsStates";
import ProfileActionsWindow from "../../../profileActionsWindow/ProfileActionsWindow";
import ReactHintFactory from "react-hint";

const ReactHint = ReactHintFactory(React);

function BonusModal ({ setVisible, setVisibleRules, visible }) {
  const { t } = useTranslation("siteOptions");
  const [bonusUsersList, setBonusUsersList] = useState(null);
  const [personalData, setPersonalData] = useState(null);
  const [loading, setLoading] = useState(false);
  const { user } = useContext(AppContext);
  const { handleProfileActionsVisible } = useBetween(ProfileActionsStates);
  const ref = useRef(null);

  const fetchPersonalData = () => {
    axios.get("/api/bonus-balances?itemsPerPage=5", userAuthenticationConfig()).then(response => {
      if (response.status === responseStatus.HTTP_OK && response.data) {
        setPersonalData(response.data);
        setBonusUsersList(response.data?.withdraws);
      }
    }).catch(error => {
      closableNotification(error.response.data.message, "error");
    });
  };

  const getLeftForMinimumUnlock = () => {
    if (!personalData) {
      return null;
    }

    let amount = depositBonuses.minValue / depositBonuses.rate - personalData.wagered;

    return amount > 0 ? amount.toLocaleString("en-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 }) : 0;
  };

  useEffect(() => {
    if (!visible) {
      return;
    }

    fetchPersonalData();
  }, [visible]);

  const handleWithdraw = () => {
    if (!personalData?.unBlockedBalance || (personalData?.unBlockedBalance < depositBonuses.minValue)) return;
    setLoading(true);
    axios.post("/api/bonuses/withdraw", {}, userAuthenticationConfig(false)).then((response) => {
      if (response.status === responseStatus.HTTP_OK) {
        closableNotification("Success", "success");
        setVisible(false);
      }
    }).catch((error) => {
      if (error.response.status === responseStatus.HTTP_BAD_REQUEST) {
        closableNotification(error.response.data.error, "error");
      }
    }).finally(() => setLoading(false));
  };

  const renderHint = (target) => {
    const { id, nickname } = target.dataset;

    return <ProfileActionsWindow
        placement="bonus"
        parentRef={ref}
        button={target}
        keys={+id}
        nickname={nickname}
    />;
  };

  return (
    <StyledBonusDialog
      visible={visible}
      wrapClassName="default-modal-wrapper"
      onClose={() => setVisible(false)}
      animation="zoom"
      maskAnimation="fade"
      title={`Tether (USDT) ${t("bonusModal.rakeback")}`}
      forceRender={false}
      className="default-modal default-modal-bonus"
    >
      <StyledBonusWrapper>
        <StyledTitleBlock className={"title-bonus-modal"}>
          <b>{personalData?.unBlockedBalance ?? 0} USDT</b>
          <span>{t("bonusModal.unlocked")}</span>
        </StyledTitleBlock>
        <StyledButtonBonus onClick={handleWithdraw} disabled={loading} className={!personalData?.unBlockedBalance || (personalData?.unBlockedBalance < depositBonuses.minValue) ? "disabled" : ""}>
          {!loading ? t("bonusModal.claim")
          : <div className={"spinner-box"}>
            <Spinner size={16} display={"block"} clasName={"spinner"} />
          </div>}
        </StyledButtonBonus>
        <StyledMinimumLimit>{t("bonusModal.minimumGet")}: {depositBonuses.minValue} USDT</StyledMinimumLimit>
        <StyledTextBlock paddingBottom="5" className={"bonus-modal-block"}>
          <div className={"blocked-info-block"}>
            <span>{t("bonusModal.blocked")} USDT:</span>
            <b>{personalData?.blockedBalance ?? 0} USDT</b>
          </div>
        </StyledTextBlock>
        <StyledTextBlock ref={ref} style={{position: "relative", background: "transparent", padding: 0}} className={"claim-info-block bonus-modal-block"}>
          <ReactHint
              attribute="data-custom"
              className="custom-hint"
              onRenderContent={renderHint}
              events={{ click: true }}
          />
          <StyledTextBlock paddingBottom="0" className={"claim-info-block bonus-modal-block"}>
          <h3>{t("bonusModal.howClaim")}</h3>
          <p className={"info-bonus"}>{t("bonusModal.infoBonusText")} <span className={"link"} onClick={() => {
            setVisible(false)
            setVisibleRules(true)
          }}>{t("bonusModal.infoBonusLink")}</span></p>
          <span>{t("bonusModal.unlockAmount")} = {t("bonusModal.wager")} * 1% * 20%</span>
          <p>{t("bonusModal.descPartOne")} ${getLeftForMinimumUnlock()}{t("bonusModal.descPartTwo")} {depositBonuses.minValue} USDT. <Link
            onClick={() => setVisible(false)} to={generatePath("/faq/rackeback")}>{t("bonusModal.viewDetails")}</Link>
          </p>
          </StyledTextBlock>
          <StyledTextBlock paddingBottom="0" className={"claim-info-block bonus-modal-block"}>
          <StyledStaticTable className="jackpot">
            <StyledTableSimple>
              <thead>
              <StyledHead className={"bonus-table-header"}>
                <StyledHeadTitle>
                  {t("time")}
                </StyledHeadTitle>
                <StyledHeadTitle>
                  {t("player")}
                </StyledHeadTitle>
                <StyledHeadTitle>
                  {t("amount")}
                </StyledHeadTitle>
              </StyledHead>
              </thead>
              <StyledBody>
                {bonusUsersList &&
                  bonusUsersList.map((item, key) =>
                    <StyledRow key={key} className={"bonus-row-table"}>
                      <StyledItem>
                        {TimestampToDateTimeWithSecondsABC(item.createdAt)}
                      </StyledItem>
                      <StyledItem>
                        {user?.nickname === item.user.nickname ? <StyledHistoryLink size="14" bordercolor="true" bold="unset"
                                           as={NavLink} onClick={() => setVisible(false)}
                                           to={generatePath(`/account/${item.user.nickname}`)}>
                          <div className={"nickname"}>
                            {item.user.nickname}
                          </div>
                        </StyledHistoryLink> :
                            <StyledHistoryLink
                                data-nickname={item.user.nickname}
                                className="wrapper-relative nickname"
                                onClick={() => handleProfileActionsVisible(key, "bonus")}
                                data-custom
                                data-custom-at="right"
                                data-id={key}
                                style={{position: "relative"}}
                            >
                              {item.user.nickname}
                            </StyledHistoryLink>}
                      </StyledItem>
                      <StyledItem>
                        {item.amount} {item.currency}
                      </StyledItem>
                    </StyledRow>)
                }
              </StyledBody>
            </StyledTableSimple>
          </StyledStaticTable>
          </StyledTextBlock>
        </StyledTextBlock>
        {bonusUsersList && bonusUsersList.length > 5 && <StyledViewMore
          as={NavLink} to={"/transaction/bonuses"}
          onClick={() => setVisible(false)}>{t("vMore")}</StyledViewMore>}
      </StyledBonusWrapper>
    </StyledBonusDialog>
  );
}

export default BonusModal;